import { useNavigate } from 'react-router-dom';
import '../../styles/datanotfound.css';

const GraphDataNotFound = () => {
    const navigate = useNavigate();

    const returnViewSensors = () => {
        localStorage.removeItem('actual-sensor');
        navigate('/sensors-view');
    };

    return (
        <div className="container-not-found">
            <p>No se encontraron datos</p>
            <button onClick={returnViewSensors} className="return">
                Ver sensores
            </button>
        </div>
    );
};

export default GraphDataNotFound;

