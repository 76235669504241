import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useNavigate } from 'react-router-dom';
import API from '../api/api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalUpdateThreshold = ({
    showModalThresholdInterGraph,
    initialMaxThresholdGraph,
    actualMaxThresholdGraph,
    initialMinThresholdGraph,
    actualMinThresholdGraph,
    unitsGraph,
    varIdGraph,
    sensorIdGraph,
    setShowModalThreshold
}) => {
    const navigate = useNavigate();
    
    // Utilizar props directamente en lugar de duplicar estados
    const [actualMaxThreshold, setActualMaxThreshold] = useState(actualMaxThresholdGraph);
    const [actualMinThreshold, setActualMinThreshold] = useState(actualMinThresholdGraph);
    const [isSaving, setIsSaving] = useState(false);  // Para manejar el estado de guardado

    const step = varIdGraph === 'CE' ? 0.1 : 1;

    useEffect(() => {
        // Sincronizar valores internos con las props cada vez que cambien
        setActualMaxThreshold(actualMaxThresholdGraph);
        setActualMinThreshold(actualMinThresholdGraph);
    }, [actualMaxThresholdGraph, actualMinThresholdGraph]);

    const handleChangeThreshold = (type, value) => {
        const realValue = Number(value);
        if (type === 'max' && realValue > actualMinThreshold) {
            setActualMaxThreshold(realValue);
        } else if (type === 'min' && realValue < actualMaxThreshold) {
            setActualMinThreshold(realValue);
        }
    };

    const handleUpdateThreshold = () => {
        setIsSaving(true); // Indicar que se está guardando
        const threshold = {
            variableId: varIdGraph,
            sensorId: sensorIdGraph,
            minValue: actualMinThreshold,
            maxValue: actualMaxThreshold,
            minLimit: initialMinThresholdGraph,
            maxLimit: initialMaxThresholdGraph
        };

        API.post('/sensors/update-threshold', threshold, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(() => {
            setIsSaving(false);
            setShowModalThreshold(false);  // Cierra el modal después de guardar
        })
        .catch((error) => {
            setIsSaving(false);
            if (error.response.status === 401) {
                localStorage.removeItem('user-jwt');
                localStorage.removeItem('actual-sensor');
                navigate('/');
            } else {
                navigate('/error');
            }
        });
    };

    return (
        <Modal show={showModalThresholdInterGraph} onHide={() => setShowModalThreshold(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Modifica tu nuevo umbral máximo y mínimo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Valor máximo</h4>
                <h6>Actualmente en: {`${actualMaxThreshold} ${unitsGraph}`}</h6>
                <Slider
                    value={actualMaxThreshold}
                    min={initialMinThresholdGraph}
                    max={initialMaxThresholdGraph}
                    step={step}
                    onChange={(value) => handleChangeThreshold('max', value)}
                />
                <br />
                <h6>Nuevo valor máximo:</h6>
                <Form.Control
                    readOnly
                    type="text"
                    placeholder="Valor máximo"
                    value={`${actualMaxThreshold} ${unitsGraph}`}
                />
                <hr />
                <h4>Valor mínimo</h4>
                <h6>Actualmente en: {`${actualMinThreshold} ${unitsGraph}`}</h6>
                <Slider
                    value={actualMinThreshold}
                    min={initialMinThresholdGraph}
                    max={initialMaxThresholdGraph}
                    step={step}
                    onChange={(value) => handleChangeThreshold('min', value)}
                />
                <br />
                <h6>Nuevo valor mínimo:</h6>
                <Form.Control
                    readOnly
                    type="text"
                    placeholder="Valor mínimo"
                    value={`${actualMinThreshold} ${unitsGraph}`}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => setShowModalThreshold(false)}>
                    Cancelar
                </Button>
                <Button 
                    variant="success" 
                    onClick={handleUpdateThreshold} 
                    disabled={isSaving} // Deshabilitar el botón mientras se guarda
                >
                    {isSaving ? 'Guardando...' : 'Guardar cambios'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalUpdateThreshold;