import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { objectHasEmptyValues } from "../utils/FormValidations";
import BarComponent from "../userbar/BarComponent";
import { Link, useParams } from "react-router-dom";
import { VARIABLES } from "../utils/Constants";
import addIcon from '../../img/add-icon.svg';
import API from "../api/api";

const SensorsEditView = () => {
    const { id } = useParams(); // Usar el hook useParams para obtener el id de la URL
    const [sensor, setSensor] = useState(null);
    const [soilTypes, setSoilTypes] = useState([]);
    // const [crops, setCrops] = useState([]);
    const [soilSensor, setSoilSensor] = useState([]);
    const [loading, setLoading] = useState(false);
    const [threshold, setThreshold] = useState([]);
    const [sims, setSims] = useState([]);
    const [getDataTimeout, setGetDataTimeout] = useState(null);

    const getSimsAutocomplete = useCallback(() => {
        if (sensor?.sim?.iccid) {
            API.get(`/simcards/all?page=1&limit=3&iccid=${sensor.sim.iccid}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            }).then(res => {
                setSims(res.data.content);
            }).catch(err => console.error(err));
        }
    }, [sensor?.sim?.iccid]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const [soils, data] = await Promise.all([
                    API.get('soilsensors/all', {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                        }
                    }),
                    API.get(`sensors/${id}`, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                        }
                    })
                ]);

                const thresholdData = data.data.threshold.map(thr => ({
                    ...thr,
                    variableId: thr.variableId.substring(0, 2),
                    soilIdSensor: parseInt(thr.variableId.charAt(2)) || 1
                }));

                setSoilTypes(soils.data);
                setSensor(data.data.sensor);
                setSoilSensor(data.data.soilSensor);
                setThreshold(thresholdData);
                
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const updateSensor = (e) => {
        e.preventDefault();
        if (objectHasEmptyValues(sensor)) return alert('Todos los campos son requeridos');

        const formatVariables = threshold.map(va => {
            const { soilIdSensor, ...restOfThreshold } = va;
            if (soilIdSensor > 1) {
                return {
                    ...restOfThreshold,
                    variableId: va.variableId + soilIdSensor
                };
            }
            return restOfThreshold;
        });

        const { crop, ...sensorWithoutCrop } = sensor; // Eliminar la propiedad crop
        API.put(`sensors/${sensor.id}`, {
            sensor: sensorWithoutCrop,
            threshold: formatVariables,
            soilSensor
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(() => alert('Sensor actualizado correctamente'))
            .catch(err => {
                console.log(err);
                alert('Error al agregar el sensor');
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const isBooleanValue = name === 'sendMail' || name === 'active';

        if (name === 'sim' && sensor) {
            setSensor(prevSensor => ({
                ...prevSensor,
                sim: {
                    iccid: value,
                    id: sims.find(sim => sim.iccid === value)?.id
                }
            }));

            if (getDataTimeout) clearTimeout(getDataTimeout);
            setGetDataTimeout(setTimeout(getSimsAutocomplete, 1000));
            return;
        }

        setSensor(prevSensor => ({
            ...prevSensor,
            [name]: isBooleanValue ? value === 'true' : value
        }));
    };

    const handleThresholdValue = (e, variableId) => {
        const { name, value } = e.target;
        const parsedValue = parseInt(value);
        setThreshold(prevThreshold =>
            prevThreshold.map(threshold =>
                threshold.variableId === variableId
                    ? { ...threshold, [name]: isNaN(parsedValue) ? "" : parsedValue }
                    : threshold
            )
        );
    };

    const setSimValue = (sim) => {
        setSensor(prevSensor => ({
            ...prevSensor,
            sim: {
                iccid: sim.iccid,
                id: sim.id
            }
        }));
    };

    const setVariableToShow = (e, soilId) => {
        const { name, checked } = e.target;
        setThreshold(prevThreshold =>
            !checked
                ? prevThreshold.filter(threshold => threshold.variableId !== name)
                : [
                    ...prevThreshold,
                    {
                        soilIdSensor: soilId,
                        variableId: name,
                        minValue: 0,
                        maxValue: 100,
                        minLimit: 0,
                        maxLimit: 100
                    }
                ]
        );
    };

    const addSonda = () => {
        setSoilSensor(prevSoilSensor => [
            ...prevSoilSensor,
            {
                soilIdSensor: '',
                soilNumber: prevSoilSensor.length + 1
            }
        ]);
    };

    const handleSoilChange = (e) => {
        const { value, name } = e.target;
        setSoilSensor(prevSoilSensor =>
            prevSoilSensor.map(soil =>
                soil.soilNumber === parseInt(name)
                    ? { ...soil, soilIdSensor: value }
                    : soil
            )
        );
    };

    const handleSondaDelete = (soilNumber) => {
        setSoilSensor(prevSoilSensor =>
            prevSoilSensor.filter(soil => soil.soilNumber !== soilNumber)
        );
        setThreshold(prevThreshold =>
            prevThreshold.filter(threshold => threshold.soilIdSensor !== soilNumber)
        );
    };

    const simsAutocomplete = sims.filter(sim => sim.iccid.includes(sensor?.sim?.iccid) && sim.iccid !== sensor?.sim?.iccid);

    return (
        <>
            <BarComponent propsBar={{ title: 'Editar sensor', isMainPage: true }} />
            <section className="container">
                {loading || !sensor ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only" />
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <Form onSubmit={updateSensor} className="my-5">
                            <div className='mt-2'>
                                <Form.Label>Id de sensor<span className="text-danger">*</span></Form.Label>
                                <Form.Control value={sensor.id} onChange={handleChange} maxLength={10} required type="text" name="id" />
                            </div>
                            <div className="mt-2">
                                <Form.Label>Nombre de sensor<span className="text-danger">*</span></Form.Label>
                                <Form.Control value={sensor.name} onChange={handleChange} required type="text" name="name" />
                            </div>
                            <div className="mt-2">
                                <Form.Label>Activo<span className="text-danger">*</span></Form.Label>
                                <select value={sensor.active} onChange={handleChange} required name="active">
                                    <option defaultValue disabled value="">Selecciona el estado</option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className="mt-2">
                                <Form.Label>Inicio de operaciones<span className="text-danger">*</span></Form.Label>
                                <Form.Control value={sensor.startOperation} onChange={handleChange} required type="date" name="startOperation" />
                            </div>
                            <div className="mt-2">
                                <Form.Label>Correo de alerta</Form.Label>
                                <div>
                                    <input
                                        id="yes"
                                        type="radio"
                                        onChange={handleChange}
                                        checked={sensor.sendMail}
                                        name="sendMail"
                                        value={true}
                                        className='mr-1'
                                    />
                                    <label htmlFor="yes">Si</label>
                                    <div>
                                        <input
                                            id="no"
                                            type="radio"
                                            onChange={handleChange}
                                            checked={!sensor.sendMail}
                                            name="sendMail"
                                            value={false}
                                            className='mr-1'
                                        />
                                        <label htmlFor="no">No</label>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2 position-relative">
                                <Form.Label>Id de sim<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    value={sensor.sim?.iccid || ''}
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    name="sim"
                                />
                                {simsAutocomplete.length > 0 && sensor.sim?.iccid.length > 0 && (
                                    <div style={{ zIndex: 20 }} className="position-absolute bg-white w-100 border rounded">
                                        {simsAutocomplete.map((sim, i) => (
                                            <p key={i} onClick={() => setSimValue(sim)} className="p-1 m-0 autocomplete-option">{sim.iccid}</p>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <hr />

                            <div className='my-3 d-flex flex-column'>
                                <div className='limits-container'>
                                    {VARIABLES.filter(v => !v.sonda).map(variable => {
                                        const variableCheck = threshold.find(th => th.variableId.startsWith(variable.id));
                                        return (
                                            <div key={variable.label} className="mt-2 d-flex flex-column limit-card-container">
                                                <Form.Check type="checkbox" onChange={e => setVariableToShow(e)} checked={!!variableCheck} label={variable.label} name={variable.id} className='w-50' />
                                                {variableCheck && (
                                                    <div className='limits-card d-flex'>
                                                        <div>
                                                            <p>Rangos de medición del sensor</p>
                                                            <Form.Label>Valor máximo %<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxValue} type="number" name="maxValue" />
                                                            <Form.Label className='mt-3'>Valor mínimo %<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minValue} type="number" name="minValue" />
                                                        </div>
                                                        <div>
                                                            <p>Valores limites para las alertas</p>
                                                            <Form.Label>Limite máximo %<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxLimit} type="number" name="maxLimit" />
                                                            <Form.Label className='mt-3'>Limite mínimo %<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minLimit} type="number" name="minLimit" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                <img src={addIcon}
                                    alt="Agregar sensor"
                                    className="add-icon my-2 align-self-end"
                                    onClick={addSonda}
                                    style={{ cursor: 'pointer' }}
                                    width="52"
                                />

                                {soilSensor.map((soilS) => (
                                    <div className='sonda-container' key={soilS.soilNumber}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h3>Sonda {soilS.soilNumber}</h3>
                                            {soilS.soilNumber > 1 && (
                                                <button
                                                    onClick={() => handleSondaDelete(soilS.soilNumber)}
                                                    className='btn py-2 btn-danger btn-sm'
                                                >
                                                    Eliminar sonda
                                                </button>
                                            )}
                                        </div>

                                        <Form.Label>Tipo de sonda<span className="text-danger">*</span></Form.Label>
                                        <select value={soilS.soilIdSensor} onChange={handleSoilChange} name={soilS.soilNumber} required className='w-75'>
                                            <option defaultValue disabled value="">Selecciona un tipo de sonda</option>
                                            {soilTypes.map((soil_type) => (
                                                <option key={crypto.randomUUID()} value={soil_type.id}>{soil_type.id}</option>
                                            ))}
                                        </select>

                                        <div className='limits-container'>
                                            {VARIABLES.filter(v => v.sonda).map(variable => {
                                                const variableCheck = threshold.find(th => th.variableId.startsWith(variable.id) && th.soilIdSensor === soilS.soilNumber);
                                                return (
                                                    <div key={variable.label} className="mt-2 d-flex flex-column limit-card-container">
                                                        <Form.Check type="checkbox" checked={!!variableCheck} onChange={e => setVariableToShow(e, soilS.soilNumber)} label={variable.label} name={variable.id} className='w-50' />
                                                        {variableCheck && (
                                                            <div className='limits-card d-flex'>
                                                                <div>
                                                                    <p>Rangos de medición del sensor</p>
                                                                    <Form.Label>Valor máximo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxValue} type="number" name="maxValue" />
                                                                    <Form.Label className='mt-3'>Valor mínimo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minValue} type="number" name="minValue" />
                                                                </div>
                                                                <div>
                                                                    <p>Valores limites para las alertas</p>
                                                                    <Form.Label>Limite máximo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxLimit} type="number" name="maxLimit" />
                                                                    <Form.Label className='mt-3'>Limite mínimo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control onChange={(e) => handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minLimit} type="number" name="minLimit" />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-4 w-100 justify-content-center align-items-center d-flex">
                                <Button type="submit" className="px-4 py-2">Actualizar</Button>
                                <Link to="/sensors-ad" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                            </div>
                        </Form>
                    </>
                )}
            </section>
        </>
    );
};

export default SensorsEditView;
