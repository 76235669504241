import React, { useEffect, useState, useCallback  } from 'react';
import VariableGraph from './VariableGraph';
import '../../styles/graphs.css';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

import '../../styles/loading.css';
import BarComponent from '../userbar/BarComponent';

import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import API from '../api/api';
import LoadingComponent from '../loading/LoadingComponent';
import { GRAPHS_LOADING_MESSAGE } from '../utils/Constants';
import GraphDataNotFound from './GraphDataNotFound';
import {
    operationForChangeVbValue,
    operationForChangeCeValue,
    operationForChangePhValue
} from '../utils/AdjustData';

const VariableGraphContainer = () => {
    const [sensor] = useState(() => { //, setSensor
        const sensorData = localStorage.getItem('actual-sensor');
        return sensorData ? JSON.parse(sensorData) : null;
    });
    const [loaded, setLoaded] = useState(false);
    const [showGraphs, setShowGraphs] = useState(false);
    const [variablesData, setVariablesData] = useState([]);
    const navigate = useNavigate();

    const setThresholdForEachGraph = useCallback((dataResponse) => {
        return dataResponse.map((dataSensor) => {
            const data = dataSensor.data;

            // Las operaciones en función de la variableId
            if (data.variableId === 'VB')
                operationForChangeVbValue(data.measures);

            if (data.variableId === 'CE')
                operationForChangeCeValue(data.measures, sensor.id);
            if (data.variableId === 'PH')
                operationForChangePhValue(data.measures, sensor.id);

            return {
                data: [
                    {
                        id: data.variableId,
                        data: data.measures,
                    },
                ],
                varId: data.variableId,
                varName: data.variableName,
                units: data.variableUnit,
                initialMaxThreshold: data.threshold.maxLimit,
                actualMaxThreshold: data.threshold.maxValue,
                initialMinThreshold: data.threshold.minLimit,
                actualMinThreshold: data.threshold.minValue,
                sensorId: sensor.id,
            };
        });
    }, [sensor.id]); // Dependencia: `sensor.id` es lo único que puede hacer que esta función cambie.

    const requestAllMeasures = useCallback(async (startDate, endDate) => {
        try {
            const responses = await Promise.all(
                orderSensorVariablesString(sensor.sensorVariablesString).map((idVariable) => {
                    const requestOptions = {
                        params: {
                            idSensor: sensor.id,
                            idVariable,
                            startDate,
                            endDate,
                            recursive: true,
                        },
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
                        },
                    };
                    return API.get('/sensors/measurements', requestOptions);
                })
            );

            const dataFound = responses[0].data.measures.length !== 0;
            setLoaded(true);
            setShowGraphs(dataFound);
            setVariablesData(setThresholdForEachGraph(responses)); // Usa la versión memoizada de `setThresholdForEachGraph`
        } catch (error) {
            navigate('/error');
        }
    }, [sensor, navigate, setThresholdForEachGraph]); // No es necesario incluir `setThresholdForEachGraph` como dependencia si ya está envuelta en `useCallback`

    useEffect(() => {
        if (sensor) {
            const startDate = format(addDays(new Date(), -1), "yyyy-MM-dd'T'00:00:00.000'-05:00'");
            const endDate = format(addDays(new Date(), 1), "yyyy-MM-dd'T'23:59:59.000'-05:00'");
            requestAllMeasures(startDate, endDate);
        }
    }, [sensor, requestAllMeasures]); // Agrega requestAllMeasures a las dependencias


    

    

    const orderSensorVariablesString = (receivedArray) => {
        const orderReference = ['TA', 'HA', 'Rd','TS', 'HS','CE','PH','NI', 'PI', 'KI',
            'TS2','HS2','CE2','PH2','NI2','PI2','KI2','VB','CB','VP','CP','Rs']
            
        // Ordenar el array según el orden de la referencia y mantener elementos no presentes en la referencia al final
        const sortedArray = [...receivedArray].sort((a, b) => {
        const indexA = orderReference.indexOf(a);
        const indexB = orderReference.indexOf(b);

        // Si ambos elementos están en la referencia, compara sus índices
        if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
        }

        // Si solo uno está en la referencia, colócalo antes
        if (indexA !== -1) {
        return -1;
        }

        if (indexB !== -1) {
        return 1;
        }

        // Si ninguno está en la referencia, no cambies su orden relativo
        return 0;
        });
        return sortedArray;
    };

    if (loaded) {
        return (
            <div>
                <BarComponent propsBar={{ isMainPage: false, title: sensor.name }} show={showGraphs} hide={!showGraphs} />
                {showGraphs ? (
                    <Container fluid="xl">
                        {variablesData.map((graphData, index) => (
                            <VariableGraph key={index} values={{ graphData }} title="sensor"/>
                        ))}
                    </Container>
                ) : (
                    <GraphDataNotFound />
                )}
            </div>
        );
    } else {
        return <LoadingComponent data={{ message: GRAPHS_LOADING_MESSAGE, type: 'success' }} />;
    }
};

export default VariableGraphContainer;