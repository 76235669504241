import React, { useState } from 'react';
import deleteIcon from '../../img/delete-icon.svg';
import { Button, Modal } from 'react-bootstrap';
import API from '../api/api';

const DeleteCrop = ({ cropId, getCrops }) => {
    const [show, setShow] = useState(false); // Hook para controlar la visibilidad del modal

    const deleteCrop = (cropId) => {
        API.delete(`crops/${cropId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(() => {
            getCrops(1); // Llamar la función `getCrops` pasada como prop
        })
        .finally(() => {
            handleClose(); // Cerrar el modal
        });
    };

    const handleClose = () => {
        setShow(false); // Actualizar el estado para cerrar el modal
    };

    return (
        <>
            <img
                src={deleteIcon}
                alt="Eliminar"
                width="32"
                className="ml-3"
                onClick={() => setShow(true)} // Mostrar el modal cuando se hace clic
                style={{ cursor: 'pointer' }}
            />
            <Modal style={{ margin: '16em 0' }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar cultivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de eliminar el cultivo con ID: {cropId}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteCrop(cropId)}>
                        Eliminar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteCrop;