export function operationForChangeVbValue(measuresVb) {
    for (let d = 0; d < measuresVb.length; d++) {
        if (measuresVb[d].y > 3) {
            measuresVb[d].y = Math.round(((measuresVb[d].y - 3) / 1.25) * 100 * 100) / 100;
        } else {
            measuresVb[d].y = 0;
        }
    }
}

export function operationForChangeCeValue(measuresCE, idSensor) {
    let sensorChange = ['SN0010', 'SN0011', 'SN0015', 'SN0024', 'SN0028', 'SN0029', 'SN0031'];

    if (sensorChange.indexOf(idSensor) !== -1) {
        for (let d = 0; d < measuresCE.length; d++) {
            measuresCE[d].y = parseFloat((measuresCE[d].y * 10).toFixed(2));
        }
    }
}

export function operationForChangePhValue(masuerePH, idSensor) {
    let sensorChange = ['SN0029', 'SN0031'];

    if (sensorChange.indexOf(idSensor) !== -1) {
        for (let d = 0; d < masuerePH.length; d++) {
            masuerePH[d].y = parseFloat((masuerePH[d].y * 1.4).toFixed(1));
        }
    }
}