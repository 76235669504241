import React, { useState, useEffect } from "react";
import { Button, Form, Pagination } from "react-bootstrap";
import { objectHasEmptyValues } from "../utils/FormValidations"; //objectDiffValues
import BarComponent from "../userbar/BarComponent";
import { Link } from "react-router-dom";

import searchIcon from '../../img/search-icon.svg';
import eyeOpen from '../../img/eye-icon.svg';
import eyeClose from '../../img/eye-slash-icon.svg';

import '../../styles/userview.css';
import API from "../api/api";

const initialValues = {
    firstName: "asd",
    lastName: "sad",
    email: "asda@gmail.com",
    address: "dsfdsf",
    password: "asdasd",
    phone: "123123",
    sensors: []
};

const itemsPerPage = 9;

const UsersEditView = () => {
    const [user, setUser] = useState(initialValues);
    const [sensorSearch, setSensorSearch] = useState('');
    const [sensors, setSensors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showPassword, setShowPassword] = useState(false);

    const getSensors = (page, search = 'test') => {
        API.get(`/sensors/all?page=${page}&limit=${itemsPerPage}&idSensor=${search}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(({ data }) => {
                setSensors(data.content);
                setTotalPages(data.totalPages);
                setCurrentPage(page);
            })
            .catch(() => alert('Error al obtener los sensores'));
    };

    useEffect(() => {
        getSensors(1); // Load sensors on mount
    }, []);

    const createUser = (e) => {
        e.preventDefault();

        const { sensors, ...restOfUser } = user;
        if (objectHasEmptyValues(restOfUser)) return alert('Todos los campos son requeridos');

        API.post('/user/create', { user: restOfUser, sensors }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(() => {
                alert('Usuario creado correctamente');
                setUser(initialValues);
            })
            .catch((err) => {
                alert(err.response.data.message || 'Error al crear el usuario');
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone' && value.length > 10) return;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleSensorSearch = () => {
        getSensors(1, sensorSearch);
    };

    const handleSensorSelected = (e) => {
        const { checked, id } = e.target;
        setUser(prevState => ({
            ...prevState,
            sensors: checked
                ? [...prevState.sensors, id]
                : prevState.sensors.filter(sensor => sensor !== id)
        }));
    };

    const handlePageChange = (page) => {
        if (page === currentPage || page < 1 || page > totalPages) return;
        setCurrentPage(page);
        getSensors(page);
    };

    const isButtonDisabled = objectHasEmptyValues(user);

    return (
        <>
            <BarComponent propsBar={{ title: 'Agregar usuario', isMainPage: true }} />
            <section className="container">
                <Form onSubmit={createUser} className="my-3">
                    <div className="d-flex w-100">
                        <div className="mt-2 mr-3 w-100">
                            <Form.Label>Nombres<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={user.firstName} type="text" name="firstName" />
                        </div>
                        <div className="mt-2 w-100">
                            <Form.Label>Apellidos<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={user.lastName} type="text" name="lastName" />
                        </div>
                    </div>
                    <div className="mt-2">
                        <Form.Label>Correo<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={user.email} type="email" name="email" />
                    </div>
                    <div className="mt-2">
                        <Form.Label>Contraseña<span className="text-danger">*</span></Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                required
                                onChange={handleChange}
                                value={user.password}
                                className="pr-4"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                            />
                            <div
                                style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }} // Centrado verticalmente
                                onClick={() => setShowPassword(prev => !prev)}
                                className="position-absolute pointer-event"
                            >
                                {showPassword ? (
                                    <img src={eyeClose} className="pointer-event" width={24} alt="search" />
                                ) : (
                                    <img src={eyeOpen} className="pointer-event" width={24} alt="search" />
                                )}
                            </div>
                        </div>
                        <small style={{ opacity: 0.7 }} className="ml-1">La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial</small>
                    </div>
                    <div className="mt-2">
                        <Form.Label>Dirección<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={user.address} type="text" name="address" />
                    </div>
                    <div className="mt-2">
                        <Form.Label>Teléfono<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={user.phone} type="text" name="phone" />
                    </div>

                    <div className="mt-5">
                        <h3>Sensores</h3>
                        <div className="position-relative sensor-form d-flex">
                            <Form.Control
                                onKeyPress={handleKeyPress}
                                onChange={(e) => setSensorSearch(e.target.value)}
                                value={sensorSearch}
                                type="text"
                                placeholder="Buscar sensor"
                                className="pl-5 input-with-icon" // Añade la clase personalizada aquí
                            />
                            <div style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} className="position-absolute p-2">
                                <img src={searchIcon} width={19} alt="search" />
                            </div>
                            <Button className="ml-2 px-4" type="button" onClick={handleSensorSearch}>Buscar</Button>
                        </div>

                        {user.sensors.length > 0 && (
                            <div className="d-flex flex-column my-3">
                                <p style={{ marginBottom: '8px' }}>Seleccionados: </p>
                                <div className="sensors-selected-container">
                                    {user.sensors.map(sensor => (
                                        <p key={sensor} className="sensor-selected">{sensor}</p>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="sensors-container">
                            {sensors.map((sensor, index) => {
                                const isChecked = user.sensors.includes(sensor.id);
                                return (
                                    <div key={index} className="card-sensor">
                                        <Form.Check type="checkbox" checked={isChecked} onChange={handleSensorSelected} id={sensor.id} label={sensor.id} />
                                    </div>
                                );
                            })}
                        </div>

                        <Pagination className="d-flex justify-content-center">
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                        </Pagination>
                    </div>

                    <div className="mt-5 w-100 justify-content-center align-items-center d-flex">
                        <Button disabled={isButtonDisabled} type="submit" className="px-4 py-2">Agregar</Button>
                        <Link to="/users" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                    </div>
                </Form>
            </section>
        </>
    );
};

export default UsersEditView;