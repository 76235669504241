import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/login.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import mainLogo from '../../img/LogoVIT1_opt.png';

import API from './../api/api';
import { useNavigate } from 'react-router-dom'; // Importamos el hook `useNavigate`

const Login = () => {
  const [username, setUsername] = useState(localStorage.getItem('user-email') || "");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const navigate = useNavigate(); // Creamos la función `navigate` para manejar la navegación

  useEffect(() => {
    if (localStorage.getItem('user-jwt') && localStorage.getItem('user-email')) {
      navigate('/sensors-view'); // Redirige a la vista de sensores si ya está logueado
    }
  }, [navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    API.post('/auth/login', {
      'Email': username,
      'Password': password
    })
      .then((res) => {
        if (res.data !== null && res.data !== '') {
          const authoritiesArray = res.data.authorities;
          setErrorPassword(false);
          localStorage.setItem('user-jwt', res.data.jwt);
          localStorage.setItem('authorities', JSON.stringify(authoritiesArray));
          navigate('/sensors-view'); // Redirige a la vista de sensores
        } else {
          setErrorPassword(true);
        }
      })
      .catch(() => {
        setErrorPassword(true);
      });
  };

  const handleCloseAlert = () => {
    setErrorPassword(false);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordShown((prevState) => !prevState);
  };

  useEffect(() => {
    if (username) {
      localStorage.setItem('user-email', username); // Guardamos el email cuando el componente se desmonta
    }
  }, [username]);

  return (
    <div className="ContainerLoginRegister">
      <Row>
        <div className="CardContainer">
          <div className="LoginContainer">
            <img className="LogoVIT" src={mainLogo} alt="Logo VIT" />

            <h1 className="TitleLogIn"><b>Ingresa a Agroup</b></h1>

            <Alert dismissible onClose={handleCloseAlert} show={errorPassword} variant='danger'>
              Ups, tus credenciales no son las correctas. Intenta de nuevo
            </Alert>
            <Form className="FormLogIn" onChange={handleChange}>
              <div id="contra">
                <Form.Group>
                  <Form.Control
                    className="InputEmail"
                    type="email"
                    placeholder="Email"
                    name="username"
                    value={username}
                    onChange={handleChange}
                  />

                  <Form.Control
                    className="InputPass"
                    type={isPasswordShown ? "text" : "password"}
                    placeholder="Contraseña"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />

                  <i
                    className={isPasswordShown ? "fa fa-eye-slash" : "fa fa-eye password-icon"}
                    onClick={togglePasswordVisibility}
                  />
                </Form.Group>
              </div>
              <h5>¿Olvidaste tu contraseña? Contáctate con nosotros al correo vitsensors@gmail.com</h5>
              <Button type="button" className="btn-block w-100" onClick={handleLogin}>
                Iniciar sesión
              </Button>
            </Form>
          </div>

          <div className="SignUpContainer">
            <h1 className="TextAside"><b>Bienvenido/a</b></h1>
            <h5>¡Si lo puedes medir, lo puedes controlar y optimizar!</h5>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Login;