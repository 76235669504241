import React, { useState } from 'react';
import deleteIcon from '../../img/delete-icon.svg';
import { Button, Modal, Spinner } from 'react-bootstrap';
import API from '../api/api';

const DeleteSimModal = ({ simId, getSims }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const deleteSimcard = (sim) => {
        setLoading(true);
        API.delete(`/simcards/${sim}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
            },
        })
        .then(() => {
            handleClose();
            alert('Simcard eliminada correctamente');
        })
        .catch(() => alert('Error al eliminar la simcard'))
        .finally(() => {
            getSims();
            setLoading(false);
        });
    };

    const handleClose = () => setShow(false);

    return (
        <>
            <img
                src={deleteIcon}
                alt="Eliminar"
                width="32"
                className="ml-3"
                onClick={() => setShow(true)}
                style={{ cursor: 'pointer' }}
            />
            <Modal style={{ margin: '16em 0' }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Simcard</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de eliminar la simcard con ID: {simId}?</Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="danger" 
                        className='w-25' 
                        onClick={() => deleteSimcard(simId)}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Eliminar'}
                    </Button>
                    <Button variant="secondary" className='w-25' onClick={handleClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteSimModal;