import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom/client'; // Importa `createRoot` desde react-dom/client
import { HashRouter, Routes, Route } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";

// Importa todos tus componentes y rutas aquí
import Login from "./components/login/Login";
import SensorsView from "./components/sensorsview/SensorsView";
import VariableGraphContainer from "./components/sensorgraphs/VariableGraphContainer";
import Simcards from "./components/simcards/Simcards";
import SimcardAddView from "./components/simcards/SimcardAddView";
import SimcardEditView from "./components/simcards/SimcardEditView";
import SensorsAd from "./components/sensorsAd/SensorsAd";
import SensorsAddView from "./components/sensorsAd/SensorsAddView";
import SensorsEditView from "./components/sensorsAd/SensorsEditView";
import UsersView from "./components/users/UsersView";
import UsersAddView from "./components/users/UsersAddView";
import UsersEditView from "./components/users/UsersEditView";
import CropsView from "./components/crops/CropsView";
import CropsAddView from "./components/crops/CropsAddView";
import CropsEditView from "./components/crops/CropsEditView";
import ResumenView from "./components/resumen/ResumenView";
import ErrorComponent from "./components/error/ErrorComponent";
import RoleProtectedComponent from "./components/roleProtectedComponent/RoleProtectedComponent";

// Usar createRoot para renderizar la app
const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  const [orientation, setOrientation] = useState(window.orientation);

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setOrientation(window.orientation);
    });
  }, []);

  if (isMobile && orientation !== 90 && orientation !== -90) {
    return (
      <Modal show={true} onHide={() => {}}>
        <Modal.Body>
          Para poder continuar, por favor cambia la orientación del dispositivo a horizontal.
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <HashRouter>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<Login />} />
        <Route path="/sensors-view" element={<SensorsView />} />
        <Route path="/graphs" element={<VariableGraphContainer />} />

        {/* Rutas protegidas */}
        <Route path="/simcards" element={<RoleProtectedComponent element={<Simcards />} />} />
        <Route path="/simcards/add" element={<RoleProtectedComponent element={<SimcardAddView />} />} />
        <Route path="/simcards/edit/:id" element={<RoleProtectedComponent element={<SimcardEditView />} />} />

        <Route path="/sensors-ad" element={<RoleProtectedComponent element={<SensorsAd />} />} />
        <Route path="/sensors-ad/add" element={<RoleProtectedComponent element={<SensorsAddView />} />} />
        <Route path="/sensors-ad/edit/:id" element={<RoleProtectedComponent element={<SensorsEditView />} />} />

        <Route path="/users" element={<RoleProtectedComponent element={<UsersView />} />} />
        <Route path="/users/add" element={<RoleProtectedComponent element={<UsersAddView />} />} />
        <Route path="/users/edit/:id" element={<RoleProtectedComponent element={<UsersEditView />} />} />

        <Route path="/crops" element={<RoleProtectedComponent element={<CropsView />} />} />
        <Route path="/crops/add" element={<RoleProtectedComponent element={<CropsAddView />} />} />
        <Route path="/crops/edit/:id" element={<RoleProtectedComponent element={<CropsEditView />} />} />

        <Route path="/resumen" element={<RoleProtectedComponent element={<ResumenView />} />} />

        <Route path="*" element={<ErrorComponent />} />
      </Routes>
    </HashRouter>
  );
};

// Renderiza la aplicación con createRoot
root.render(<Root />);
