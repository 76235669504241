import React, { useEffect, useState } from 'react';
import SensorCard from './SensorCard';
import API from './../api/api';
import BarComponent from '../userbar/BarComponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/sensorsview.css';
import LoadingComponent from '../loading/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { SENSORS_LOADING_MESSAGE } from '../utils/Constants';

const SensorsView = () => {
    const [sensors, setSensors] = useState([]);
    const [isFetched, setIsFetched] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showSensors, setShowSensors] = useState(false);
    // const [currentSensorName, setCurrentSensorName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (localStorage.getItem('user-email')) {
                setTimeout(() => setShowSensors(true), 1500);
                try {
                    const res = await API.get('/sensors/sensors-from', {
                        params: {
                            idUser: localStorage.getItem('user-email'),
                        },
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
                        },
                    });
                    setSensors(res.data);
                    setIsFetched(true);
                } catch (error) {
                    setIsFetched(false);
                    setIsError(true);
                    localStorage.removeItem('user-jwt');
                    localStorage.removeItem('actual-sensor');
                    navigate('/');
                }
            } else {
                navigate('/error');
            }
        };
        fetchData();
    }, [navigate]);

    const loadingProps = {
        message: SENSORS_LOADING_MESSAGE,
        type: 'primary',
    };

    const propsBar = {
        isMainPage: true,
        title: 'Sensores',
    };

    if (isFetched && !isError && showSensors) {
        return (
            <div id="todo">
                <div>
                    <BarComponent propsBar={propsBar} />
                    <Container id="tarjetasSensores">
                        {sensors.map(sensor => (
                            <Row key={sensor.id}>
                                <SensorCard 
                                    // setSensorNameFunction={setCurrentSensorName} 
                                    handleShow={null} // asumiendo que 'handleShow' se pasa como prop si es necesario
                                    values={sensor} 
                                />
                            </Row>
                        ))}
                    </Container>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <LoadingComponent data={loadingProps} />
            </div>
        );
    }
};

export default SensorsView;