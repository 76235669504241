import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { objectDiffValues, objectHasEmptyValues } from "../utils/FormValidations";
import BarComponent from "../userbar/BarComponent";
import { Link, useParams } from "react-router-dom";
import API from "../api/api";
import { operators } from "../utils/Constants";

const SimcardEditView = () => {
    const { id } = useParams(); // Obtener el ID de la tarjeta SIM desde la URL
    const [sim, setSim] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [originalSimcard, setOriginalSimcard] = useState({});

    useEffect(() => {
        setLoading(true);
        API.get(`/simcards/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(res => {
                const isDeactivated = res.data.status === 'desactivado';
                const simParsed = {
                    ...res.data,
                    activationDate: res.data.activationDate.split('T')[0],
                    deactivationDate: isDeactivated ? res.data.deactivationDate.split('T')[0] : new Date().toISOString().split('T')[0],
                    deactivationReason: isDeactivated ? res.data.deactivationReason : 'Desactivado'
                };
                setOriginalSimcard(simParsed);
                setSim(simParsed);
            })
            .finally(() => setLoading(false));
    }, [id]);

    const updateSimcard = (evt) => {
        evt.preventDefault();
        const values = { ...sim };
        const hasEmptyValues = objectHasEmptyValues(values);
        const iccidLength = values.iccid.length;
        const dateYear = values.activationDate.split('-')[0];

        if (hasEmptyValues) return alert('Por favor, llena todos los campos');
        if (iccidLength !== 19) return alert(`El iccid debe tener 19 dígitos. El iccid ingresado tiene ${iccidLength} dígitos`);
        if (dateYear > new Date().getFullYear()) return alert('La fecha de activación no puede ser mayor al año actual');
        if (values.status !== 'desactivado') {
            delete values.deactivationDate;
            delete values.deactivationReason;
        }

        const valuesToUpdate = objectDiffValues(originalSimcard, values);
        if (Object.keys(valuesToUpdate).length === 0) return alert('No hay cambios para actualizar');

        setLoadingUpdate(true);
        API.put(`/simcards/${sim.id}`, values, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(({ data }) => {
                const isDeactivated = data.status === 'desactivado';
                const simParsed = {
                    ...data,
                    activationDate: data.activationDate.split('T')[0],
                    deactivationDate: isDeactivated ? data.deactivationDate.split('T')[0] : new Date().toISOString().split('T')[0],
                    deactivationReason: isDeactivated ? data.deactivationReason : 'Desactivado'
                };
                setOriginalSimcard(simParsed);
                alert('Simcard actualizada correctamente');
            })
            .finally(() => setLoadingUpdate(false));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSim((prevSim) => ({
            ...prevSim,
            [name]: value
        }));
    };

    const isButtonDisabled = objectHasEmptyValues(sim);

    return (
        <>
            <BarComponent propsBar={{ title: 'Editar Simcard', isMainPage: true }} />
            <section className="container">
                <Form onSubmit={updateSimcard} className="my-5">
                    <h2>Simcard: {id}</h2>

                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only" />
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            <div className="mt-2">
                                <Form.Label>ICCID<span className="text-danger">*</span></Form.Label>
                                <Form.Control required onChange={handleChange} value={sim.iccid} type="number" name="iccid" />
                            </div>
                            <div className="mt-2">
                                <Form.Label>Fecha de activación<span className="text-danger">*</span></Form.Label>
                                <Form.Control required onChange={handleChange} value={sim.activationDate} type="date" name="activationDate" />
                            </div>
                            <div className="mt-2">
                                <Form.Label>Operador<span className="text-danger">*</span></Form.Label>
                                <select onChange={handleChange} value={sim.operator} name="operator">
                                    {operators.map(operator => (
                                        <option key={operator.value} value={operator.value}>{operator.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-2">
                                <Form.Label>Estado<span className="text-danger">*</span></Form.Label>
                                <select onChange={handleChange} value={sim.status} name="status">
                                    <option value="activo">Activo</option>
                                    <option value="dañado">Dañado</option>
                                    <option value="desactivado">Desactivado</option>
                                </select>
                            </div>

                            {sim.status === 'desactivado' && (
                                <>
                                    <div className="mt-2">
                                        <Form.Label>Fecha de desactivación<span className="text-danger">*</span></Form.Label>
                                        <Form.Control required disabled value={sim.deactivationDate} type="date" name="deactivationDate" />
                                    </div>
                                    <div className="mt-2">
                                        <Form.Label>Razón de desactivación<span className="text-danger">*</span></Form.Label>
                                        <Form.Control required onChange={handleChange} value={sim.deactivationReason} type="text" name="deactivationReason" />
                                    </div>
                                </>
                            )}

                            <div className="mt-4 w-100 buttons-container justify-content-center align-items-center d-flex">
                                <Button disabled={isButtonDisabled} type="submit" className="px-4 bg-success py-2">
                                    {loadingUpdate ? <Spinner animation="border" size="sm" /> : 'Actualizar'}
                                </Button>
                                <Link to="/simcards" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                            </div>
                        </>
                    )}
                </Form>
            </section>
        </>
    );
};

export default SimcardEditView;