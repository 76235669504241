import React, { useState, useEffect } from "react";
import { Button, Form, Pagination, Spinner } from "react-bootstrap";
import BarComponent from '../userbar/BarComponent';
import addIcon from '../../img/add-icon.svg';
import editIcon from '../../img/edit_icon.png';
import { Link, useNavigate } from "react-router-dom"; // Importamos el hook `useNavigate`
import '../../styles/sensorsad.css';
import searchIcon from '../../img/search-icon.svg';
import DeleteSensorModal from "./DeleteSensorModal";
import API from "../api/api";

const itemsPerPage = 5;

const SensorsAd = () => {
  const [sensors, setSensors] = useState([]);
  // const [filteredSensors, setFilteredSensors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    idSensor: "",
    active: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate(); // Usamos el hook `useNavigate`

  // Llamada inicial para obtener sensores al cargar el componente
  useEffect(() => {
    getSensors(1);
  }, []);

  // Obtener sensores con paginación
  const getSensors = (page) => {
    setLoading(true);
    API.get(`/sensors/all?page=${page}&limit=${itemsPerPage}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
      }
    })
      .then(({ data }) => {
        setSensors(data.content);
        // setFilteredSensors(data.content);  // Aquí guardamos la lista de sensores filtrados
        setTotalPages(data.totalPages);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  // Manejar la búsqueda con filtros
  const handleSubmit = (e) => {
    e.preventDefault();

    const values = Object.fromEntries(new FormData(e.target));

    // Eliminamos los filtros vacíos
    for (const fil in values) {
      if (!values[fil].trim()) delete values[fil];
    }

    const filterValues = Object.entries(values).join('&').replaceAll(',', '=');

    setLoading(true);
    API.get(`/sensors/all?page=1&limit=${itemsPerPage}&${filterValues}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
      }
    })
      .then(({ data }) => {
        setSensors(data.content);
        setCurrentPage(1);
        setTotalPages(data.totalPages);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  // Manejar cambios en los filtros
  const handleChange = (e) => {
    const { name, value } = e.target;
    const isBooleanValue = name === 'active' && value !== "";
    setFilters({
      ...filters,
      [name]: isBooleanValue ? value === "true" : value
    });
  };

  // Manejar el cambio de página
  const handlePageChange = (page) => {
    if (page === currentPage || page < 1 || page > totalPages) return;
    setCurrentPage(page);
    getSensors(page);
  };

  return (
    <>
      <BarComponent propsBar={{ title: 'Sensores', isMainPage: false }} />
      <section className="container">

        <header className="d-flex justify-content-center flex-column mt-5">

          <Form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className="position-relative d-flex">
              <Form.Control
                type="text"
                onChange={handleChange}
                value={filters.idSensor}
                name="idSensor"
                placeholder="ID del sensor"
                className="pl-5 input-with-icon"
              />
              <div style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} className="position-absolute p-2">
                  <img src={searchIcon} width={19} alt="search" />
              </div>

              <Button type="submit" className="ml-2 px-4">Buscar</Button>
            </div>

            <div className="d-flex select-container align-items-center align-self-center mt-4">
              <p className="m-0 mr-2">Estado</p>
              <select name="active" onChange={handleChange} value={filters.active} className="mr-2">
                <option value="">Todos</option>
                <option value={true}>Activo</option>
                <option value={false}>No activo</option>
              </select>
            </div>
          </Form>

          <hr width='90%' />

          <Link to="/sensors-ad/add" className="align-self-end">
            <img src={addIcon} alt="Agregar sensor" className="add-icon" width="52" />
          </Link>
        </header>

        <div className="sims-container text-center mb-5 my-3">
          {loading && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only" />
              </Spinner>
            </div>
          )}
          {error && <h2>Error al cargar los sensores</h2>}
          {sensors.length === 0 && !loading && !error
            ? <h2>No hay resultados con los filtros seleccionados</h2>
            : (
              sensors.map(sensor => (
                <div key={sensor.id} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                  <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                    <div className="d-flex flex-column">
                      <p className="mb-0">Id sensor</p>
                      <h5 className="mb-0">{sensor.id}</h5>
                    </div>
                  </div>
                  <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                    <div className="d-flex flex-column">
                      <p className="mb-0">Nombre Sensor</p>
                      <h5 className="mb-0">{sensor.name}</h5>
                    </div>
                  </div>
                  <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                    <div className="d-flex flex-column">
                      <p className="mb-0">Estado</p>
                      <h5 className="mb-0 text-capitalize">{sensor.active ? 'Activo' : 'Inactivo'}</h5>
                    </div>
                  </div>
                  <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                    <img
                      src={editIcon}
                      alt="Editar"
                      width="25"
                      onClick={() => navigate(`/sensors-ad/edit/${sensor.id}`)} // Usamos `navigate` en lugar de `history.push`
                      style={{ cursor: 'pointer' }}
                    />
                    <DeleteSensorModal sensorId={sensor.id} getSensors={() => getSensors(currentPage)} />
                  </div>
                </div>
              ))
            )
          }
        </div>

        <Pagination className="d-flex justify-content-center">
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        </Pagination>
      </section>
    </>
  );
};

export default SensorsAd;