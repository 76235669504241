import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { objectHasEmptyValues } from "../utils/FormValidations";
import BarComponent from "../userbar/BarComponent";
import { Link } from "react-router-dom";
import API from "../api/api";
import { operators } from "../utils/Constants";

const initialValues = {
    iccid: "",
    activationDate: "",
    operator: "",
    status: "",
    deactivationDate: new Date().toISOString().split('T')[0],
    deactivationReason: "Desactivado"
};

const SimcardAddView = () => {
    const [sim, setSim] = useState(initialValues);
    const [loading, setLoading] = useState(false);

    const addSimcard = (e) => {
        e.preventDefault();

        const values = { ...sim };
        const hasEmptyValues = objectHasEmptyValues(values);
        const iccidLength = values.iccid.length;
        const dateYear = values.activationDate.split('-')[0];

        if (hasEmptyValues) return alert('Por favor, llena todos los campos');
        if (iccidLength !== 19) return alert(`El iccid debe tener 19 dígitos\nEl iccid ingresado tiene ${iccidLength} dígitos`);
        if (dateYear > new Date().getFullYear()) return alert('La fecha de activación no puede ser mayor al año actual');
        if (values.status !== 'desactivado') {
            delete values.deactivationDate;
            delete values.deactivationReason;
        }

        setLoading(true);
        API.post('/simcards/create', values, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(() => {
                setSim(initialValues);
                alert('Simcard agregada correctamente');
            })
            .catch(() => alert('Error al agregar la simcard'))
            .finally(() => setLoading(false));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSim((prevSim) => ({
            ...prevSim,
            [name]: value
        }));
    };

    const isButtonDisabled = objectHasEmptyValues(sim);

    return (
        <>
            <BarComponent propsBar={{ title: 'Agregar Simcard', isMainPage: true }} />
            <section className="container">
                <Form onSubmit={addSimcard} className="my-5">
                    <div className="mt-2">
                        <Form.Label>ICCID<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={sim.iccid} type="number" name="iccid" />
                    </div>
                    <div className="mt-2">
                        <Form.Label>Fecha de activación<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={sim.activationDate} type="date" name="activationDate" />
                    </div>
                    <div className="mt-2">
                        <Form.Label>Operador<span className="text-danger">*</span></Form.Label>
                        <select required onChange={handleChange} value={sim.operator} name="operator">
                            <option defaultValue disabled value="">Selecciona un operador</option>
                            {operators.map(operator => (
                                <option key={operator.value} value={operator.value}>{operator.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mt-2">
                        <Form.Label>Estado<span className="text-danger">*</span></Form.Label>
                        <select required onChange={handleChange} value={sim.status} name="status">
                            <option defaultValue disabled value="">Selecciona un estado</option>
                            <option value="activo">Activo</option>
                            <option value="dañado">Dañado</option>
                            <option value="desactivado">Desactivado</option>
                        </select>
                    </div>
                    {sim.status === 'desactivado' && (
                        <>
                            <div className="mt-2">
                                <Form.Label>Fecha de desactivación<span className="text-danger">*</span></Form.Label>
                                <Form.Control required disabled value={sim.deactivationDate} type="date" name="deactivationDate" />
                            </div>
                            <div className="mt-2">
                                <Form.Label>Razón de desactivación<span className="text-danger">*</span></Form.Label>
                                <Form.Control required onChange={handleChange} value={sim.deactivationReason} type="text" name="deactivationReason" />
                            </div>
                        </>
                    )}

                    <div className="mt-4 buttons-container w-100 justify-content-center align-items-center d-flex">
                        <Button disabled={isButtonDisabled} type="submit" className="px-4 py-2">
                            {loading ? <Spinner animation="border" size="sm" /> : 'Agregar'}
                        </Button>
                        <Link to="/simcards" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                    </div>
                </Form>
            </section>
        </>
    );
};

export default SimcardAddView;