import React, { useState, useEffect } from "react";
import { Button, Form, Pagination } from "react-bootstrap";
import { objectDiffValues } from "../utils/FormValidations"; // , objectHasEmptyValues 
import BarComponent from "../userbar/BarComponent";
import { Link, useParams } from "react-router-dom";

import searchIcon from '../../img/search-icon.svg';
import eyeOpen from '../../img/eye-icon.svg';
import eyeClose from '../../img/eye-slash-icon.svg';

import '../../styles/userview.css';
import API from "../api/api";

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    sensors: []
};

const itemsPerPage = 9;

const UsersEditView = ({ getUsers }) => {
    const { id } = useParams(); // Use useParams to get the user ID from the URL
    const [user, setUser] = useState(initialValues);
    const [sensorSearch, setSensorSearch] = useState('');
    const [sensors, setSensors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        // Fetch the user data when the component mounts
        API.get(`user/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(({ data }) => {
                setUser({ ...data, password: '' });
            })
            .catch(() => alert('Error al obtener el usuario'));

        // Fetch sensors when the component mounts
        getSensors(1);
    }, [id]);

    const getSensors = (page) => {
        API.get(`/sensors/all?page=${page}&limit=${itemsPerPage}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(({ data }) => {
                setSensors(data.content);
                setTotalPages(data.totalPages);
                setCurrentPage(page);
            })
            .catch(() => alert('Error al obtener los sensores'));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone' && value.length > 10) return;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleSensorSearch = () => {
        API.get(`/sensors/all?page=1&limit=${itemsPerPage}&idSensor=${sensorSearch}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(({ data }) => {
                setSensors(data.content);
                setTotalPages(data.totalPages);
                setCurrentPage(1);
            })
            .catch(() => alert('Error al obtener los sensores'));
    };

    const handleSensorSelected = (e) => {
        const { checked, id } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            sensors: checked
                ? [...prevUser.sensors, id]
                : prevUser.sensors.filter(sensor => sensor !== id)
        }));
    };

    const handlePageChange = (page) => {
        if (page === currentPage || page < 1 || page > totalPages) return;
        setCurrentPage(page);
        getSensors(page);
    };

    const updateUser = (e) => {
        e.preventDefault();
        const { sensors, ...restOfUser } = user;
        const sensorsDiff = sensors.some(sen => {
            if (sensors.length !== initialValues.sensors.length) return true;
            const found = initialValues.sensors.find(sensor => sensor === sen);
            return !found;
        });

        const valuesToUpdate = objectDiffValues(initialValues, restOfUser);

        if (sensorsDiff) valuesToUpdate.sensors = sensors;
        if (Object.keys(valuesToUpdate).length === 0 && !sensorsDiff) return alert('No se ha modificado ningún campo');

        if (restOfUser.password.trim() === '') delete restOfUser.password;

        API.put(`user/${id}`, { user: restOfUser, sensors }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
            .then(() => alert('Usuario actualizado'))
            .catch((err) => alert(err.response.data.message || 'Error al actualizar el usuario'));
    };

    return (
        <>
            <BarComponent propsBar={{ title: 'Editar usuario', isMainPage: true }} />
            <section className="container">
                <Form onSubmit={updateUser} className="my-3">
                    <div className="d-flex w-100">
                        <div className="mt-2 mr-3 w-100">
                            <Form.Label>Nombres<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={user.firstName} type="text" name="firstName" />
                        </div>
                        <div className="mt-2 w-100">
                            <Form.Label>Apellidos<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={user.lastName} type="text" name="lastName" />
                        </div>
                    </div>
                    <div className="mt-2">
                        <Form.Label>Correo<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={user.email} type="email" name="email" />
                    </div>
                    <div className="mt-2">
                        <Form.Label>Contraseña<span className="text-danger">*</span></Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                onChange={handleChange}
                                value={user.password}
                                className="pr-4"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                            />
                            <div
                                style={{ top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }} // Centrado verticalmente
                                onClick={() => setShowPassword(prev => !prev)}
                                className="position-absolute pointer-event"
                            >
                                {showPassword ? (
                                    <img src={eyeClose} className="pointer-event" width={24} alt="search" />
                                ) : (
                                    <img src={eyeOpen} className="pointer-event" width={24} alt="search" />
                                )}
                            </div>
                        </div>
                        <small style={{ opacity: 0.7 }} className="ml-1">La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial</small>
                    </div>
                    <div className="mt-2">
                        <Form.Label>Dirección<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={user.address} type="text" name="address" />
                    </div>
                    <div className="mt-2">
                        <Form.Label>Teléfono<span className="text-danger">*</span></Form.Label>
                        <Form.Control required onChange={handleChange} value={user.phone} type="text" name="phone" />
                    </div>

                    <div className="mt-5">
                        <h3>Sensores</h3>

                        <div className="position-relative sensor-form d-flex">
                            <Form.Control
                                onKeyPress={handleKeyPress}
                                onChange={(e) => setSensorSearch(e.target.value)}
                                value={sensorSearch}
                                type="text"
                                placeholder="Buscar sensor"
                                className="pl-5 input-with-icon" // Añade la clase personalizada aquí
                            />
                            <div style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} className="position-absolute p-2">
                                <img src={searchIcon} width={19} alt="search" />
                            </div>

                            <Button className="ml-2 px-4" type="button" onClick={handleSensorSearch}>Buscar</Button>
                        </div>

                        {user.sensors.length > 0 && (
                            <div className="d-flex flex-column my-3">
                                <p style={{ marginBottom: '8px' }}>Seleccionados: </p>
                                <div className="sensors-selected-container">
                                    {user.sensors.map(sensor => (
                                        <p key={sensor} className="sensor-selected">
                                            {sensor}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="sensors-container">
                            {sensors.map((sensor, index) => {
                                const isChecked = user.sensors.includes(sensor.id);
                                return (
                                    <div key={index} className="card-sensor">
                                        <Form.Check type="checkbox" checked={isChecked} onChange={handleSensorSelected} id={sensor.id} label={sensor.id} />
                                    </div>
                                );
                            })}
                        </div>

                        <Pagination className="d-flex justify-content-center">
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                        </Pagination>
                    </div>

                    <div className="mt-5 w-100 justify-content-center align-items-center d-flex">
                        <Button type="submit" className="px-4 py-2">Actualizar</Button>
                        <Link to="/users" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                    </div>
                </Form>
            </section>
        </>
    );
};

export default UsersEditView;