import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import "../../styles/loading.css";

const LoadingComponent = ({ data }) => {
    const [message, setMessage] = useState(data.message);
    const [type, setType] = useState(data.type);

    useEffect(() => {
        setMessage(data.message);  // Actualizar el mensaje si `data.message` cambia
        setType(data.type);        // Actualizar el tipo si `data.type` cambia
    }, [data]);  // Dependencia de `data`, se actualizará si `data` cambia

    return (
        <Container id="loadingContainer" className="text-center align-self-center">
            <h2>{message}</h2>
            <Spinner animation="border" role="status" variant={type}>
                <span className="sr-only" />
            </Spinner>
        </Container>
    );
};

export default LoadingComponent;