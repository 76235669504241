import React, { useState } from 'react';
import deleteIcon from '../../img/delete-icon.svg';
import { Button, Modal } from 'react-bootstrap';
import API from '../api/api';

const DeleteUserModal = ({ user, getUsers }) => {
    const [show, setShow] = useState(false);

    const deleteUser = (userEmail) => {
        API.delete(`user/${userEmail}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(() => {
            setShow(false);
            getUsers();
        })
        .catch((err) => console.log(err))
        .finally(() => setShow(false));
    };

    const handleClose = () => setShow(false);

    return (
        <>
            <img
                src={deleteIcon}
                alt="Eliminar"
                width="32"
                className="ml-3"
                onClick={() => setShow(true)}
                style={{ cursor: 'pointer' }}
            />
            <Modal style={{ margin: '16em 0' }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de eliminar el usuario <span className='font-weight-bold'>{user.firstName}</span> con correo: {user.email}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteUser(user.email)}>
                        Eliminar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteUserModal;