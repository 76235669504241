import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import API from './../api/api';
import LoadingComponent from '../loading/LoadingComponent';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CsvDownloadButton from 'react-json-to-csv';
import {
    operationForChangeVbValue,
    operationForChangeCeValue,
    operationForChangePhValue,
} from '../utils/AdjustData';
import { graphColors } from '../utils/GraphUtils';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-day-picker/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/graphs.css';
import ModalUpdateThreshold from './ModalUpdateThreshold';
import ModalSelectDateMeasure from './ModalSelectDateMeasure';

const VariableGraph = ({ values }) => {
    const navigate = useNavigate();
    const graphData = values.graphData;

    // States
    const [sensorId] = useState(graphData.sensorId);
    const [varId] = useState(graphData.varId);
    const [varName] = useState(graphData.varName);
    const [data, setData] = useState(graphData.data);
    const [units] = useState(graphData.units);
    const [color] = useState(graphColors[graphData.data[0].id]);
    const [range, setRange] = useState({ from: undefined, to: undefined });
    const [oldRange, setOldRange] = useState({ from: undefined, to: undefined });
    const [showModalThreshold, setShowModalThreshold] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [loaded, setLoaded] = useState(true);
    
    // Effect to handle validation of varId
    useEffect(() => {
        if (!varId) {
            navigate('/error');
        }
    }, [varId, sensorId, navigate]);

    // Loading properties
    const loadingProps = {
        message: '',
        type: 'success',
    };

    // Format date to a readable format
    const formatDate = (date) => {
        const pad = (num) => (num < 10 ? '0' + num : num);
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    
    // Convert UTC time to local time format
    const convertToLocalTime = (data) => {
        return data.map(item => {
            const localDate = new Date(item.x);
            return {
                ...item,
                x: formatDate(localDate),
            };
        });
    };

    // Fetch measurements based on the selected date range
    const requestMeasures = async (startDate, endDate) => {
        setLoaded(false);
        try {
            const res = await API.get('/sensors/measurements', {
                params: {
                    idSensor: sensorId,
                    idVariable: varId,
                    startDate: startDate,
                    endDate: endDate,
                    recursive: false,
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
                },
            });

            if (res.status === 200) {
                const measures = res.data.measures || [];
                if (measures.length > 0) {
                    if (varId === 'VB') operationForChangeVbValue(measures);
                    if (varId === 'CE') operationForChangeCeValue(measures, sensorId);
                    if (varId === 'PH') operationForChangePhValue(measures, sensorId);

                    setData([{ id: varId, data: measures }]);
                    setNoDataAvailable(false);
                } else {
                    toggleDatePickerVisibility();
                    setNoDataAvailable(true);
                }
            } else {
                console.error('Error fetching data:', res);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('user-jwt');
                localStorage.removeItem('actual-sensor');
                navigate('/');
            }
        } finally {
            setLoaded(true);
        }
    };

    // Toggle visibility of the date picker modal
    const toggleDatePickerVisibility = () => {
        setShowDatePicker((prev) => !prev);
        // setShowDatePicker(!showDatePicker);
    };

    return (
        <Container id={'_graph' + varId} className="_graph">
            <Row id="ContainerTitleGraph">
                <h3 id="TitleGraph">{varName}</h3>
                
                <Col id="ContainerButtonsTitle">
                <CsvDownloadButton 
                        id={'csvButton' + varId}
                        data={data.length > 0 ? convertToLocalTime(data[0].data) : []}
                        filename={varName}
                        headers={[varName, 'Hora']}
                        target="_blank"
                        title="Download CSV"
                    >
                        <div className="ButtonCSV"/>
                    </CsvDownloadButton>
                    <Button className="ButtonConfImage" title='Threshold' onClick={() => setShowModalThreshold(true)} />
                    <Button className="ButtonFilterImage" title='Datapicker' onClick={toggleDatePickerVisibility} />
                </Col>
                
                {showDatePicker && (
                        <ModalSelectDateMeasure
                            showDatePickerInterGraph = {showDatePicker}
                            noDataAvailableInterGraph = {noDataAvailable}
                            changeDatePickerVisibility = {toggleDatePickerVisibility}
                            range = {range}
                            oldRange = {oldRange}
                            setOldRange = {setOldRange}
                            setRange = {setRange}
                            varIdGraph={varId}
                            data = {data}
                            setData = {setData}
                            requestMeasures = {requestMeasures}
                        />
                )}
                {showModalThreshold && (
                        <ModalUpdateThreshold 
                            showModalThresholdInterGraph={showModalThreshold}
                            initialMaxThresholdGraph={graphData.initialMaxThreshold}
                            actualMaxThresholdGraph={graphData.actualMaxThreshold}
                            initialMinThresholdGraph={graphData.initialMinThreshold}
                            actualMinThresholdGraph={graphData.actualMinThreshold}
                            unitsGraph={graphData.units}
                            varIdGraph={varId}
                            sensorIdGraph={sensorId}
                            setShowModalThreshold={setShowModalThreshold}
                        />
                )}
            </Row>

            <Row id="GraphRow">
                {!loaded && <LoadingComponent data={loadingProps} />}
                {loaded && (
                    <ResponsiveLine
                        colors={color}
                        enableArea
                        areaBaselineValue={0}
                        areaOpacity={0.04}
                        lineWidth={3}
                        margin={{ top: 40, right: 20, bottom: 70, left: 80 }}
                        data={data}
                        animate={false}
                        enablePoints={false}
                        curve="monotoneX"
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%dT%H:%M:%S.%L%Z', 
                            precision: 'second',
                            useUTC: true,
                        }}
                        xFormat="time:%Y-%m-%d-%H:%M:%S"
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            reverse: false,
                        }}
                        yFormat=",.2f"
                        axisLeft={{
                            orient: 'left',
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: units,
                            legendOffset: -50,
                            legendPosition: 'middle',
                        }}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 6,
                            tickPadding: 2,
                            tickRotation: 30,
                            legend: 'Fecha',
                            legendOffset: 60,
                            legendPadding: 50,
                            legendPosition: 'middle',
                            format:
                                data.length > 0 && data[0].data.length > 800
                                    ? '%b %d'
                                    : '%b %d - %H:%M',
                            tickValues:
                                data.length > 0 && data[0].data.length > 800
                                    ? 'every 1 day'
                                    : 'every 2 hours',
                        }}
                        enableSlices={'x'}
                        sliceTooltip={({ slice }) => (
                            <div>
                                {slice.points.map((point, index) => (
                                    <div key={index} id="tooltipSlice">
                                        Fecha: {point.data.xFormatted} <br />
                                        Valor: {point.data.yFormatted} {units}
                                    </div>
                                ))}
                            </div>
                        )}
                        useMesh={true}
                    />
                )}
            </Row>
        </Container>
    );
};

export default VariableGraph;