import React, { useState, useEffect } from "react";
import { Button, Form, Pagination, Spinner } from "react-bootstrap";
import BarComponent from '../userbar/BarComponent';
import '../../styles/crops.css';
import addIcon from '../../img/add-icon.svg';
import editIcon from '../../img/edit_icon.png';
import { Link, useNavigate } from "react-router-dom"; // Importamos el hook `useNavigate` correctamente
import searchIcon from '../../img/search-icon.svg';
import DeleteCrop from "./DeleteCrop";
import API from "../api/api";

const itemsPerPage = 5;

const CropsView = () => {
  const [crops, setCrops] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate(); // Usamos el hook useNavigate para la navegación

  // Llamada inicial para obtener cultivos
  useEffect(() => {
    getCrops(1);
  }, []);

  // Función para obtener cultivos
  const getCrops = (page) => {
    setLoading(true);
    API.get(`/crops/all?page=${page}&limit=${itemsPerPage}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
      }
    })
      .then(res => {
        setCrops(res.data.content);
        setCurrentPage(page);
      })
      .catch(err => setError(true))
      .finally(() => setLoading(false));
  };

  // Manejar el envío del formulario de búsqueda
  const handleSubmit = (e) => {
    e.preventDefault();
    const filterValues = Object.fromEntries(new FormData(e.target));

    setLoading(true);
    API.get(`/crops/all?page=1&limit=${itemsPerPage}&${filterValues.name ? `name=${filterValues.name}` : ''}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
      }
    })
      .then(res => {
        setCrops(res.data.content);
        setCurrentPage(1);
      })
      .catch(err => setError(true))
      .finally(() => setLoading(false));
  };

  // Manejar el cambio de página
  const handlePageChange = (page) => {
    if (page === currentPage || page < 1) return;
    setCurrentPage(page);
    getCrops(page);
  };

  if(!error)
  {
    return (
      <>
        <BarComponent propsBar={{ title: 'Cultivos', isMainPage: false }} />
        <section className="container">
  
          <header className="d-flex justify-content-center flex-column mt-5">
            <Form onSubmit={handleSubmit} className="d-flex flex-column">
              <div className="position-relative d-flex">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nombre del cultivo"
                  className="pl-5 input-with-icon" // Añade la clase personalizada aquí
                />
                <div style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} className="position-absolute p-2">
                    <img src={searchIcon} width={19} alt="search" />
                </div>
                <Button type="submit" className="ml-2 px-4">Buscar</Button>
              </div>
            </Form>
  
            <hr width="90%" />
  
            <Link to="/crops/add" className="align-self-end">
              <img src={addIcon} alt="Agregar cultivo" className="add-icon" width="52" />
            </Link>
          </header>
  
          <div className="sims-container text-center mb-5 my-3">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              crops.length === 0 ? (
                <h2>No hay resultados con los filtros seleccionados</h2>
              ) : (
                crops.map(crop => (
                  <div key={crop.id} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                    <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                      <div className="d-flex flex-column">
                        <p className="mb-0">Empresa</p>
                        <h5 className="mb-0">{crop.company}</h5>
                      </div>
                    </div>
                    <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                      <div className="d-flex flex-column">
                        <p className="mb-0">Nombre</p>
                        <h5 className="mb-0">{crop.name}</h5>
                      </div>
                    </div>
                    <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                      <div className="d-flex flex-column">
                        <p className="mb-0">Ubicación</p>
                        <h5 className="mb-0">{crop.location}</h5>
                      </div>
                    </div>
                    <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                      <div className="d-flex flex-column">
                        <p className="mb-0">Cultivo</p>
                        <h5 className="mb-0">{crop.crop}</h5>
                      </div>
                    </div>
  
                    <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                      <img
                        src={editIcon}
                        alt="Editar"
                        width="25"
                        onClick={() => navigate(`/crops/edit/${crop.id}`)}
                        style={{ cursor: 'pointer' }}
                      />
                      <DeleteCrop getCrops={getCrops} cropId={crop.id} />
                    </div>
                  </div>
                ))
              )
            )}
          </div>
  
          <Pagination className="d-flex justify-content-center">
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
            {Array.from({ length: 5 }, (_, i) => ( // Aquí debemos asegurarnos de tener un número válido de páginas
              <Pagination.Item
                key={i}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
          </Pagination>
        </section>
      </>
    );
  }
  else
    return (
      <>
        <div>Error Cultivos</div>
      </>
  );
  
};

export default CropsView;