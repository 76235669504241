import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import notifications_image from '../../img/notifications_2.png';
import profile_icon from '../../img/user.png';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import API from '../api/api';
import { useNavigate } from 'react-router-dom';
import mainLogoBlanco from '../../img/Logo_blanco.png';
import backArrow from '../../img/previous.png';
import '../../styles/nav.css';

const BarComponent = ({ propsBar }) => {
    const [listNotifications, setListNotifications] = useState([]);
    const title = propsBar.title || '';
    const [isMainPage] = useState(propsBar.isMainPage);
    const navigate = useNavigate();
    const testing = true;

    useEffect(() => {
        const fetchNotifications = async () => {
            if (localStorage.getItem('user-email') && !testing) {
                try {
                    const res = await API.get('/sensors/get-alerts', {
                        params: {
                            idUser: localStorage.getItem('user-email'),
                        },
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
                        },
                    });
                    setListNotifications(res.data);
                } catch (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('user-jwt');
                        localStorage.removeItem('actual-sensor');
                        navigate('/');
                    } else {
                        navigate('/error');
                    }
                }
            }
        };
        fetchNotifications();
    }, [navigate,testing]);

    const logoutHandler = async () => {
        try {
            await API.get('/user/updateDate', {
                params: {
                    email: localStorage.getItem('user-email'),
                    updateDate: new Date().toISOString(),
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
                },
            });
            localStorage.clear();
            navigate('/');
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('user-jwt');
                localStorage.removeItem('actual-sensor');
                navigate('/');
            } else {
                navigate('/error');
            }
        }
    };

    const onClickImageHandler = () => {
        navigate('/sensors-view');
    };

    return (
        <Navbar bg="dark" variant="dark" className="justify-content-between" id="nav-bar">
            <Navbar.Brand onClick={onClickImageHandler}>
                <img
                    src={isMainPage ? mainLogoBlanco : backArrow}
                    width="60"
                    height="35"
                    className="d-inline-block align-top"
                    alt="logo icon"
                    id="logo-vit"
                />
            </Navbar.Brand>
            <h3 id="nav-title">{title}</h3>
            <Nav>
                <NavDropdown className="mr-sm-2" align="end" title={
                    <img
                        src={notifications_image}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="notifications icon"
                    />
                } id="nav-dropdown-basic">
                    <div className="notifications">
                        {listNotifications.length !== 0 ? (
                            <ListGroup id="miList">
                                {listNotifications.map((notification, index) => (
                                    <ListGroup.Item className="desple" key={index}>
                                        Sensor: {notification.idSensor}, Variable: {notification.variable.readableName}, Fecha: {format(new Date(notification.dateMeasure), "yyyy-MM-dd")}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) : (
                            <div>No hay notificaciones</div>
                        )}
                    </div>
                </NavDropdown>

                <NavDropdown align="end" title={
                    <img
                        src={profile_icon}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="profile icon"
                        id="miImage"
                    />
                } id="nav-dropdown">
                    <div className="conte">
                        <Dropdown.Item className="desple pt-2" id="dd" onClick={logoutHandler}>
                            <i className="fa fa-sign-out"/> Salir
                        </Dropdown.Item>
                    </div>
                </NavDropdown>
            </Nav>
        </Navbar>
    );
};

export default BarComponent;