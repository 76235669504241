import { useState} from 'react';
import { DayPicker } from 'react-day-picker';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { es } from 'date-fns/locale';
import format from 'date-fns/format';

const ModalSelectDateMeasure = ({
    showDatePickerInterGraph,
    noDataAvailableInterGraph,
    changeDatePickerVisibility,
    range,
    oldRange,
    setOldRange,
    setRange,
    varIdGraph,
    data,
    setData,
    requestMeasures,
}) => {
    // Desestructuración de props
    const from = range.from ? new Date(range.from) : null;
    const to = range.to ? new Date(range.to) : null;
    
    const [key, setKey] = useState(0);

    const addDayToRange = (day, currentRange) => {
        const { from, to } = currentRange;
        
        if (!from) { //|| (to && day < from)
            return { from: day, to: undefined };
        }
        else if (from && !to) {
            if(day > from)
                return { ...currentRange, to: day };
            else if(day <= from)
            {
                return { from: day, to: from };
            }
        }
        else if (from && to ) {
            if ((day < from) && (day < to)){
                return { ...currentRange, from: day };
            }

            else if ((day >= from) && (day < to || day > to)) {
                return { ...currentRange, to: day };
            }

            else if ((day >= from) && (day <= to)) {
                return { from: day, to: day };
            }
        }
        return { from: undefined, to: undefined };//return currentRange;
    };

    const handleDayClick = (day) => {
        setOldRange(range);
        const newRange = addDayToRange(day, range);
        setRange(newRange);
    };

    const handleClickReset = () => {
        setRange({ from: undefined, to: undefined });
        setKey(prevKey => prevKey + 1); // Forzar re-renderizado
    };

    const selectDate = (e) => {
        e.preventDefault();
        if (from !== undefined) {
            const startDate = new Date(from);
            startDate.setHours(0, 0, 0, 0); // Establecer al inicio del día (00:00:00)
            const endDate = new Date(to);
            endDate.setHours(23, 59, 59, 999); // Establecer al final del día (23:59:59.999)
            
            if (range.from >= oldRange.from && range.to <= oldRange.to) 
            {
                const newMeasures = data[0].data.filter(measure => {
                    const measureDate = new Date(measure.x);
                    return measureDate >= startDate && measureDate <= endDate;
                });

                if (newMeasures.length) {
                    setData([{ id: varIdGraph, data: newMeasures }]);
                } 

            }
            else {
                // const startDate = format(from, "yyyy-MM-dd'T'00:00:00.000'-05:00'");
                // const endDate = format(to, "yyyy-MM-dd'T'23:59:59.000'-05:00'");
                requestMeasures(startDate, endDate);
            }
            changeDatePickerVisibility();

        }
    };

    return (
        <Modal show={showDatePickerInterGraph} onHide={changeDatePickerVisibility} centered>
            <Modal.Header closeButton>
                <Modal.Title>Selecciona un rango de fechas para hacer el filtro</Modal.Title>
            </Modal.Header>
            <Modal.Body className="ModalGraph">
                {noDataAvailableInterGraph && (
                    <p id="alertDataNoAvaliable">No hay datos disponibles en las fechas seleccionadas.</p>
                )}
                <p id="selector-date-id">
                    {!from && !to && 'Selecciona la primera fecha.'}
                    {from && !to && 'Selecciona la fecha final.'}
                    {from && to && `Seleccionaste desde ${from.toLocaleDateString()} hasta ${to.toLocaleDateString()}`}
                    {from && to && (
                        <Button className="ButtonResetDate" title="ResetDate" onClick={handleClickReset}>
                            Reiniciar
                        </Button>
                    )}
                </p>
                <DayPicker
                    key={key}
                    className="Selectable"
                    locale={es}
                    weekStartsOn={0}
                    mode="range"
                    selected={{ from, to }}
                    onDayClick={handleDayClick}
                    formatters={{
                        formatCaption: (month) => {
                            const monthName = month.toLocaleString('es-ES', { month: 'long' });
                            const year = month.getFullYear();
                            return `${capitalizeFirstLetter(monthName)} ${year}`;
                        },
                    }}
                />
                <Button
                    className="ButtonOkCalendarStyle"
                    type="button"
                    disabled={!to}
                    onClick={selectDate}
                >
                    Aplicar
                </Button>
            </Modal.Body>
        </Modal>
    );
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default ModalSelectDateMeasure;