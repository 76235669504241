import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { objectHasEmptyValues } from "../utils/FormValidations";
import BarComponent from "../userbar/BarComponent";
import { Link, useParams } from "react-router-dom";
import '../../styles/userview.css';
import API from "../api/api";

const examplevalues = {
    id: "Cultivo4",
    description: "Registro de prueba 2",
    company: "Empresa Agrícola del Meta",
    location: "Restrepo, Meta",
    crop: "Tomate",
    variety: "Rio Grande",
    cropAge: "1 año",
    timeToHarvest: "2 meses",
    area: "0.5 hectáreas",
    soilType: "Arenoso",
    cropHistory: "Previamente maíz",
    irrigation: "Riego por aspersión",
    emitter: "Microaspersor",
    volume: "5 litros por hora",
    frequency: "Dos veces al día",
    emitterFlow: "1 litro por minuto",
    waterCollection: "No",
    fertilizerFrequency: "Cada dos semanas",
    fertilizerType: "granular",
    advisoryService: false,
    previousPlantings: false,
    soilAnalysis: true,
    engine: "motobomba",
    productivityPerPlant: "3 kg por año",
    measurementElementManagement: "Manual",
    peopleInArea: 2,
    infrastructure: "Cultivo a cielo abierto",
    contact: "María López",
    phone: 3117890123,
    email: "maria.lopez@empresaagricoladelmeta.com",
    observations: "Se observa un ligero ataque de plagas"
};

const CropsEditView = () => {
    const { id } = useParams(); // Obtener el ID del cultivo de la URL
    const [crop, setCrop] = useState(examplevalues);
    const [loading, setLoading] = useState(false);
    // const [sensors, setSensors] = useState(['sad787']);
    const [sensorIdAutocomplete, setSensorIdAutocomplete] = useState([]);
    const [sensor, setSensor] = useState('sad787');
    
    const getDataTimeout = useRef(null); // Para mantener la referencia al timeout

    // Función para actualizar el cultivo
    const updateCrop = (e) => {
        e.preventDefault();

        const values = { ...crop, phone: parseInt(crop.phone) };
        if (objectHasEmptyValues(values)) return alert('Todos los campos son requeridos');

        const valuesWithoutSensors = { ...crop };
        delete valuesWithoutSensors.sensors;

        API.put(`/crops/${crop.id}`, { crop: valuesWithoutSensors, sensors: [sensor] }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(res => {
            alert('Cultivo actualizado correctamente');
            setCrop(res.data);
        });
    };

    // Función para obtener autocompletado de sensores
    const getSensorsAutocomplete = useCallback(() => {
        API.get(`/sensors/all?page=1&limit=10&idSensor=${sensor}`,{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(res => {
            setSensorIdAutocomplete(res.data.content);
        })
        .catch(err => console.error(err));
    }, [sensor]);

    // Manejar cambios de formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        const booleans = ['advisoryService', 'previousPlantings', 'soilAnalysis'];

        const isBoolean = booleans.includes(name);

        if (name === 'sensor') {
            setSensor(value);
            clearTimeout(getDataTimeout.current);
            getDataTimeout.current = setTimeout(getSensorsAutocomplete, 1000);
            return;
        }

        setCrop({
            ...crop,
            [name]: isBoolean ? value === 'true' : value
        });
    };

    // Efecto para cargar los datos al montar el componente
    useEffect(() => {
        setLoading(true);
        API.get(`/crops/${id}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(res => {
            setCrop(res.data);
            setSensor(res.data.sensors[0]);
        })
        .finally(() => setLoading(false));
    }, [id]); // Se ejecuta cuando el id cambia

    const isButtonDisabled = objectHasEmptyValues(crop);
    const filteredSensorIdAutocomplete = sensorIdAutocomplete.filter(sensor => sensor.id.includes(sensor) && sensor.id !== sensor);

    return (
        <>
            <BarComponent propsBar={{ title: 'Editar cultivo', isMainPage: true }} />
            <section className="container">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only" />
                        </Spinner>
                    </div>
                ) : (
                    <Form onSubmit={updateCrop} className="my-4">

                        {/* Sensor Field */}
                        <div className="mt-2 position-relative">
                            <Form.Label>Id de sensor<span className="text-danger">*</span></Form.Label>
                            <Form.Control value={sensor} onChange={handleChange} required type="text" name="sensor" />
                            {filteredSensorIdAutocomplete.length > 0 && sensor.length > 0 && (
                                <div className="position-absolute bg-white w-100 border rounded">
                                    {filteredSensorIdAutocomplete.map((sensorItem, i) => (
                                        <p key={i} onClick={() => setSensor(sensorItem.id)} className="p-1 m-0 autocomplete-option">{sensorItem.id}</p>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Fields */}
                        <div className="mt-2">
                            <Form.Label>Nombre<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={crop.name} type="text" name="name" />
                        </div>

                        <div className="mt-2">
                            <Form.Label>Empresa<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={crop.company} type="company" name="company" />
                        </div>

                        <div className="mt-2">
                            <Form.Label>Ubicación<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={handleChange} value={crop.location} type="text" name="location" />
                        </div>

                        {/* Inputs grid */}
                        <div className="inputs-grid-container">
                            <div className="mt-2">
                                <div className="mt-2">
                                    <Form.Label>Cultivo<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.crop} type="text" name="crop" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Edad de cultivo<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.cropAge} type="text" name="cropAge" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Area sembrada<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.area} type="text" name="area" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Tipo de suelo<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.soilType} type="text" name="soilType" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Sistema de riego<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.irrigation} type="text" name="irrigation" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Tipo de emisor<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.emitter} type="text" name="emitter" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Volumen de agua/cama o surco<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.volume} type="text" name="volume" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Frecuencia de riego<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.frequency} type="text" name="frequency" />
                                </div>
                                <div className="mt-3">
                                    <Form.Label id="fertilizerType">Tipo de fertilización<span className="text-danger">*</span></Form.Label>
                                    <div className='d-flex'>
                                        <div className='mr-3'>
                                            <label htmlFor="fertilizer_type_yes">Granular</label>
                                            <input id="fertilizer_type_yes" type="radio" onChange={handleChange} name="fertilizerType" checked={crop.fertilizerType === 'granular'} value='granular' className='ml-1' />
                                        </div>
                                        <div>
                                            <label htmlFor="fertilizer_type_no">Líquido</label>
                                                    <input id="fertilizer_type_no" type="radio" onChange={handleChange} name="fertilizerType" checked={crop.fertilizerType === 'liquido'} value='liquido' className='ml-1' />
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                
                            <div className="mt-2">
                                <div className="mt-2">
                                    <Form.Label>Variedad<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.variety} type="text" name="variety" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Tiempo de siembra a cosecha<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.timeToHarvest} type="text" name="timeToHarvest" />
                                </div>
                                <div className="mt-2">
                                    <Form.Label>Historial de cultivo<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.cropHistory} type="text" name="cropHistory" />
                                </div>
                                <div className="my-4 radios-container border p-3">
                                    <div className="checks">
                                        <p>Asesoría técnica</p>
                                        <div className='d-flex'>
                                            <div className='mr-2'>
                                                <label htmlFor="advisory_service_yes">Si</label>
                                                <input id="advisory_service_yes" type="radio" onChange={handleChange} name="advisoryService" checked={crop.advisoryService} value={true} className='ml-1' />
                                            </div>
                                            <div>
                                                <label htmlFor="advisory_service_no">No</label>
                                                <input id="advisory_service_no" type="radio" onChange={handleChange} name="advisoryService" checked={!crop.advisoryService} value={false} className='ml-1' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="checks">
                                        <p>Siembras anteriores</p>
                                        <div className='d-flex'>
                                            <div className='mr-2'>
                                                <label htmlFor="previous_plantings_yes">Si</label>
                                                <input id="previous_plantings_yes" type="radio" onChange={handleChange} name="previousPlantings" checked={crop.previousPlantings} value={true} className='ml-1' />
                                            </div>
                                            <div>
                                                <label htmlFor="previous_plantings_no">No</label>
                                                <input id="previous_plantings_no" type="radio" onChange={handleChange} name="previousPlantings" checked={!crop.previousPlantings} value={false} className='ml-1' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="checks">
                                        <p>Análisis de suelos</p>
                                        <div className='d-flex'>
                                            <div className='mr-2'>
                                                <label htmlFor="soil_analysis_yes">Si</label>
                                                <input id="soil_analysis_yes" type="radio" onChange={handleChange} name="soilAnalysis" checked={crop.soilAnalysis} value={true} className='ml-1' />
                                            </div>
                                            <div>
                                                <label htmlFor="soil_analysis_no">No</label>
                                                <input id="soil_analysis_no" type="radio" onChange={handleChange} name="soilAnalysis" checked={!crop.soilAnalysis} value={false} className='ml-1' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex mt-3 justify-content-center">
                                        <div className='d-flex'>
                                            <div className='mr-4'>
                                                <label htmlFor="yes">Motobomba</label>
                                                <input id="yes" type="radio" onChange={handleChange} name="engine" checked={crop.engine === 'motobomba'} value='motobomba' className='ml-1' />
                                            </div>
                                            <div>
                                                <label htmlFor="no">Gravedad</label>
                                                        <input id="no" type="radio" onChange={handleChange} name="engine" checked={crop.engine === 'gravedad'} value='gravedad' className='ml-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <Form.Label>Caudal del emisor<span className="text-danger">*</span></Form.Label>
                                            <Form.Control required onChange={handleChange} value={crop.emitterFlow} type="text" name="emitterFlow" />
                                        </div>
                                        <div className="mt-2">
                                            <Form.Label>Captación del agua<span className="text-danger">*</span></Form.Label>
                                            <Form.Control required onChange={handleChange} as="textarea" value={crop.waterCollection} type="text" name="waterCollection" />
                                        </div>
                                        <div className="mt-2">
                                            <Form.Label>Frecuencia de fertilización<span className="text-danger">*</span></Form.Label>
                                            <Form.Control required onChange={handleChange} as="textarea" value={crop.fertilizerFrequency} type="text" name="fertilizerFrequency" />
                                        </div>
                                    </div>


                            </div>

                            <div className="mt-2">
                                <Form.Label>Productividad por planta o área<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} as="textarea" value={crop.productivityPerPlant} type="text" name="productivityPerPlant" />
                            </div>

                            <div className="mt-2">
                                    <Form.Label>Manejo de algún elemento de medición en cultivo<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} as="textarea" value={crop.measurementElementManagement} type="text" name="measurementElementManagement" />
                            </div>

                            <div className="mt-2">
                                    <Form.Label>Numero de personas por área<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.peopleInArea} type="number" name="peopleInArea" />
                            </div>

                            <div className="mt-2">
                                    <Form.Label>Infraestructura<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.infrastructure} type="text" name="infrastructure" />
                            </div>

                                <div className="mt-4">
                                    <Form.Label>Contacto<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} value={crop.contact} type="text" name="contact" />
                            </div>

                                <div className="my-4 d-flex justify-content-around">
                                    <div className="mr-3 w-50">
                                        <Form.Label>Teléfono<span className="text-danger">*</span></Form.Label>
                                        <Form.Control required onChange={handleChange} value={crop.phone} type="number" name="phone" />
                        </div>

                                    <div className="w-50">
                                        <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                        <Form.Control required onChange={handleChange} value={crop.email} type="email" name="email" />
                                    </div>
                        </div>

                        {/* Observations */}
                        <div className="mt-2">
                            <Form.Label>Observaciones<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required onChange={handleChange} as="textarea" value={crop.observations} name="observations" />
                        </div>

                        {/* Actions */}
                        <div className="mt-5 w-100 justify-content-center align-items-center d-flex">
                            <Button disabled={isButtonDisabled} type="submit" className="px-4 py-2">Actualizar</Button>
                            <Link to="/crops" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                        </div>

                    </Form>
                )}
            </section>
        </>
    );
};

export default CropsEditView;