import React, { useState, useEffect } from "react";
import { Button, Form, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Usamos useNavigate para la navegación
import BarComponent from '../userbar/BarComponent';
import '../../styles/simcards.css';
import addIcon from '../../img/add-icon.svg';
import editIcon from '../../img/edit_icon.png';
import { Link } from "react-router-dom";
import searchIcon from '../../img/search-icon.svg';
import DeleteUserModal from "./DeleteUserModal";
import API from '../api/api';

const itemsPerPage = 4;

const UsersView = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(3);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate(); // Usamos el hook useNavigate para la navegación

    useEffect(() => {
        getUsers(1); // Cargar los usuarios al montar el componente
    }, []);

    const getUsers = (page) => {
        setLoading(true);
        API.get(`/user/all?page=${page}&limit=${itemsPerPage}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(({ data }) => {
            setUsers(data.content);
            setTotalPages(data.totalPages);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const values = Object.fromEntries(new FormData(e.target));

        // Si el valor de un campo es un string vacío, lo eliminamos del objeto
        for (const fil in values) {
            if (!values[fil].trim()) delete values[fil];
        }

        const filterValues = Object.entries(values).join('&').replaceAll(',', '=');

        setLoading(true);
        API.get(`/user/all?page=1&limit=${itemsPerPage}&${filterValues}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(({ data }) => {
            setUsers(data.content);
            setTotalPages(data.totalPages);
            setCurrentPage(1);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    };

    const handlePageChange = (page) => {
        if (page === currentPage || page < 1 || page > totalPages) return;
        setCurrentPage(page);
        getUsers(page);
    };

    return (
        <>
            <BarComponent propsBar={{ title: 'Usuarios', isMainPage: false }} />
            <section className="container">
                <header className="d-flex justify-content-center flex-column mt-5">
                    <Form onSubmit={handleSubmit} className="d-flex flex-column">
                    <div className="position-relative d-flex">
                        <Form.Control 
                            type="text" 
                            name="email" 
                            placeholder="Correo" 
                            className="pl-5 input-with-icon" // Añade la clase personalizada aquí
                        />
                        <div style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} className="position-absolute p-2">
                            <img src={searchIcon} width={19} alt="search" />
                        </div>
                        <Button type="submit" className="ml-2 px-4">Buscar</Button>
                    </div>

                        <div className="d-flex select-container align-items-center align-self-center mt-4">
                            <p className="m-0 mr-2">Estado</p>
                            <select name="active" className="mr-2">
                                <option value="">Todos</option>
                                <option value="true">Activo</option>
                                <option value="false">No activo</option>
                            </select>
                        </div>
                    </Form>

                    <hr width='90%' />

                    <Link to="/users/add" className="align-self-end">
                        <img src={addIcon} alt="Agregar usuario" className="add-icon" width="52" />
                    </Link>
                </header>

                <div className="sims-container text-center mb-5 my-3">
                    {loading && <h2>Cargando usuarios...</h2>}
                    {error && <h2>Error al cargar los usuarios</h2>}
                    {users.length === 0 && !loading && !error ? (
                        <h2>No hay resultados con los filtros seleccionados</h2>
                    ) : (
                        users.map(user => (
                            <div key={user.email} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                                <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                    <div className="d-flex flex-column">
                                        <p className="mb-0">Correo</p>
                                        <h5 className="mb-0">{user.email}</h5>
                                    </div>
                                </div>
                                <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                    <div className="d-flex flex-column">
                                        <p className="mb-0">Nombre</p>
                                        <h5 className="mb-0">{user.firstName} {user.lastName}</h5>
                                    </div>
                                </div>

                                <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                                    <img
                                        src={editIcon}
                                        alt="Editar"
                                        width="25"
                                        onClick={() => navigate(`/users/edit/${user.email}`)} // Usamos navigate en lugar de history.push
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <DeleteUserModal getUsers={() => getUsers(currentPage)} user={user} />
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <Pagination className="d-flex justify-content-center">
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                </Pagination>
            </section>
        </>
    );
};

export default UsersView;