import React from "react";
import { useNavigate } from "react-router-dom"; // Usamos useNavigate para la navegación
import "../../styles/graphs.css";
import Container from "react-bootstrap/Container";
import "../../styles/error.css";

const ErrorComponent = () => {
  const navigate = useNavigate(); // Definimos el hook useNavigate para la navegación

  // Función para manejar el logout
  const handleLogout = () => {
    localStorage.clear();
    navigate("/"); // Redirigimos al inicio
  };

  return (
    <Container id="errorContainer" className="text-center align-self-center">
      <h2>
        Se ha presentado un error. Intenta ingresar nuevamente
        <a id="ButtonResetDate" href="/#" onClick={handleLogout}> aquí.</a>
      </h2>
      <h3>Si el problema persiste, contáctanos a info@vitsensors.com</h3>
    </Container>
  );
};

export default ErrorComponent;