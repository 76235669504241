import React, { useState } from 'react';
import deleteIcon from '../../img/delete-icon.svg';
import { Button, Modal } from 'react-bootstrap';
import API from '../api/api';

const DeleteSensorModal = ({ sensorId, getSensors }) => {
    const [show, setShow] = useState(false);

    const deleteSensor = (sensorId) => {
        API.delete(`sensors/${sensorId}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
            },
        })
        .then(() => {
            handleClose();
            getSensors();
        })
        .catch((err) => console.error(err));
    };

    const handleClose = () => setShow(false);

    return (
        <>
            <img
                src={deleteIcon}
                alt="Eliminar"
                width="32"
                className="ml-3"
                onClick={() => setShow(true)}
                style={{ cursor: 'pointer' }}
            />
            <Modal style={{ margin: '16em 0' }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar sensor</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de eliminar el sensor con ID: {sensorId}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteSensor(sensorId)}>
                        Eliminar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteSensorModal;