import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/sensorsview.css";
import API from "./../api/api";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import editIcon from "../../img/edit_icon.png";
import SensorsUtils from "../utils/SensorsUtils";

const SensorCard = ({ values }) => {
  const [sensor, setSensor] = useState(values);
  const [showConfig, setShowConfig] = useState(false);
  const [sensorName, setSensorName] = useState(values.name);
  const [sensorDescription, setSensorDescription] = useState(values.description);
  const [sensorNotifications, setSensorNotifications] = useState(values.sendMail);
  const [sensorCrop, setSensorCrop] = useState(values.crop);
  
  const navigate = useNavigate();

  const updateSensor = async (toGraph) => {
    if (localStorage.getItem("actual-sensor")) {
      localStorage.setItem("actual-sensor", "");
    }

    const sensorData = {
      ...sensor,
      ...(toGraph ? { checked: true } : {
        name: sensorName,
        crop: sensorCrop,
        description: sensorDescription,
        sendMail: sensorNotifications,
      }),
    };

    try {
      const res = await API.post("/sensors/update-sensor", sensorData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-jwt"),
        },
      });
      localStorage.setItem("actual-sensor", JSON.stringify(res.data));
      if (toGraph) {
        navigate("/graphs");
      } else {
        setSensor(res.data);
      }
    } catch (error) {
      localStorage.removeItem("user-jwt");
      localStorage.removeItem("actual-sensor");
      navigate("/");
    }
  };

  const onClick = () => {
    
    if (!sensor.checked) {
      updateSensor(true);
    } else {
      if(sensor.sensorVariablesString == null)
        updateSensor(true);
      else{
        localStorage.setItem("actual-sensor", JSON.stringify(sensor));
        navigate("/graphs");
      }
      
    }
  };

  const handleSaveSensor = () => {
    updateSensor(false);
    setShowConfig(false);
  };

  const handleShowConfig = () => setShowConfig(true);
  const handleHideConfig = () => setShowConfig(false);

  const checkboxChange = () => {
    setSensorNotifications(!sensorNotifications);
  };

  return (
    <div>
      <Card
        bg={sensor.checked ? "red" : "white"}
        border={!sensor.checked ? "danger" : "white"}
        text="black"
        className="sensor_card tarjeta_sensor"
      >
        <h6 className="CropType">{sensor.crop?.crop || sensor.description}</h6>
        <Card.Img
          variant="top"
          src={SensorsUtils.getBackgroundSensor(sensor.crop?.crop)}
          onClick={onClick}
        />
        <h6 className="StateSensor" id={sensor.active ? "colorStateActive" : "colorStateInactive"}>
          {sensor.active ? "Activo" : "Inactivo"}
        </h6>
        <Card.Body onClick={onClick}>
          <div className="TitleCardContainer">
            <Card.Title>{sensor.name || ""}</Card.Title>
          </div>
          <div className="cardDescription">{sensor.description || ""}</div>
        </Card.Body>
        <Card.Link onClick={handleShowConfig} className="linkEditIcon">
          <img className="editIcon" src={editIcon} alt="Edit Icon" />
        </Card.Link>

        <Modal
          centered
          size="sm"
          className="ModalModifySensor"
          show={showConfig}
          onHide={handleHideConfig}
        >
          <Modal.Header closeButton>
            <Modal.Title>Modificar {sensor.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Nombre del sensor</Form.Label>
                <Form.Control
                  defaultValue={sensor.name}
                  onChange={(event) => setSensorName(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Seleccione su cultivo</Form.Label>
                <Form.Control
                  defaultValue={sensor.crop?.crop}
                  as="select"
                  onChange={(event) => setSensorCrop({ crop: event.target.value, id: sensor.crop?.id })}
                >
                  <option>Flores</option>
                  <option>Palma</option>
                  <option>Café</option>
                  <option>Aguacate</option>
                  <option>Tomate</option>
                  <option>Gulupa</option>
                  <option>Arándanos</option>
                  <option>Aromáticas</option>
                  <option>Otro</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Recibir notificaciones"
                  checked={sensorNotifications}
                  onChange={checkboxChange}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  defaultValue={sensor.crop?.crop || sensor.description}
                  onChange={(event) => setSensorDescription(event.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSaveSensor}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </Card>
    </div>
  );
};

export default SensorCard;