import React, { useState, useEffect } from "react";
import { Button, Form, Pagination, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Usamos useNavigate para la navegación
import BarComponent from '../userbar/BarComponent';
import '../../styles/simcards.css';
import addIcon from '../../img/add-icon.svg';
import editIcon from '../../img/edit_icon.png';
import API from "../api/api";
import DeleteSimModal from "./DeleteSimModal";
import searchIcon from '../../img/search-icon.svg';
import { operators } from "../utils/Constants";
import { Link } from 'react-router-dom';

const itemsPerPage = 4;

const Simcards = () => {
    const [sims, setSims] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate(); // Usamos el hook useNavigate

    useEffect(() => {
        getSimCards(1);
    }, []);

    const getSimCards = (page) => {
        setLoading(true);
        API.get(`/simcards/all?page=${page}&limit=${itemsPerPage}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(({ data }) => {
            const simsParsedDate = data.content.map(sim => ({
                ...sim, 
                activationDate: sim.activationDate.split('T')[0]
            }));
            setSims(simsParsedDate);
            setTotalPages(data.totalPages);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    };

    const handlePageChange = (page) => {
        if (page === currentPage || page < 1 || page > totalPages) return;
        setCurrentPage(page);
        getSimCards(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const values = Object.fromEntries(new FormData(e.target));

        // Si el valor de un campo es un string vacío, lo eliminamos del objeto
        for (const fil in values) {
            if (!values[fil].trim()) delete values[fil];
        }

        const filterValues = Object.entries(values).join('&').replaceAll(',', '=');

        setLoading(true);
        API.get(`/simcards/all?page=1&limit=${itemsPerPage}&${filterValues}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(({ data }) => {
            const simsParsedDate = data.content.map(sim => ({
                ...sim, 
                activationDate: sim.activationDate.split('T')[0]
            }));
            setSims(simsParsedDate);
            setTotalPages(data.totalPages);
            setCurrentPage(1);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    };

    return (
        <>
            <BarComponent propsBar={{ title: 'SimCards', isMainPage: false }} />
            <section className="container">
                <header className="d-flex justify-content-center flex-column mt-5">
                    <Form onSubmit={handleSubmit} className="d-flex flex-column">
                        <div className="position-relative d-flex">
                            <Form.Control type="number" name="iccid" placeholder="ICCID" className="pl-5 input-with-icon" />
                            <div style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} className="position-absolute p-2">
                                <img src={searchIcon} width={19} alt="search" />
                            </div>
                            <Button type="submit" className="ml-2 px-4">Buscar</Button>
                        </div>

                        <div className="d-flex select-container align-items-center align-self-center mt-4">
                            <p className="m-0 mr-2">Estado</p>
                            <select name="status" className="mr-2">
                                <option value="">Todos</option>
                                <option value="activo">Activo</option>
                                <option value="dañado">Dañado</option>
                                <option value="desactivado">Desactivado</option>
                            </select>

                            <p className="m-0 mr-2 ml-4">Operador</p>
                            <select name="operator">
                                <option value="">Todos</option>
                                {operators.map(operator => (
                                    <option key={operator.value} value={operator.value}>{operator.label}</option>
                                ))}
                            </select>
                        </div>
                    </Form>

                    <hr width='90%' />

                    <Link to="/simcards/add" className="align-self-end">
                        <img src={addIcon} alt="Agregar simcard" className="add-icon" width="52" />
                    </Link>
                </header>

                <div className="sims-container text-center mb-5 my-3">
                    {loading && (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only" />
                            </Spinner>
                        </div>
                    )}
                    {error && <h2>Error al cargar las SimCards</h2>}
                    {sims.length === 0 && !loading && !error ? (
                        <h2>No hay resultados con los filtros seleccionados</h2>
                    ) : (
                        sims.map(sim => (
                            <div key={sim.id} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                                <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                    <div className="d-flex flex-column">
                                        <p className="mb-0">ID SimCard</p>
                                        <h5 className="mb-0">{sim.id}</h5>
                                    </div>
                                </div>
                                <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                    <div className="d-flex flex-column">
                                        <p className="mb-0">ICCID</p>
                                        <h5 className="mb-0" style={{ fontSize: '1.1em' }}>{sim.iccid}</h5>
                                    </div>
                                </div>
                                <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                    <div className="d-flex flex-column">
                                        <p className="mb-0">Activación</p>
                                        <h5 className="mb-0">{sim.activationDate}</h5>
                                    </div>
                                </div>
                                <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                    <div className="d-flex flex-column">
                                        <p className="mb-0">Estado</p>
                                        <h5 className="mb-0 text-capitalize">{sim.status}</h5>
                                    </div>
                                </div>
                                <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                                    <img
                                        src={editIcon}
                                        alt="Editar"
                                        width="25"
                                        onClick={() => navigate(`/simcards/edit/${sim.id}`)} // Usamos navigate en lugar de history.push
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <DeleteSimModal getSims={() => getSimCards(currentPage)} simId={sim.id} />
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <Pagination className="d-flex justify-content-center">
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                </Pagination>
            </section>
        </>
    );
};

export default Simcards;